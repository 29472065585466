import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx'; 
import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

apiURL = environment.apiUrl
plan_id: any;
reports: boolean;
reportsImg: boolean;
selected = "";
img_url = "https://spoorit.evoxyz.tech/assets/blurreport.png"
user_arr = [];
suberror: boolean;
locerror: boolean;
loc_arr = []
selecterror: boolean;
maxerror: boolean;
spinner:boolean;

	horizontalPosition: MatSnackBarHorizontalPosition = 'center';
	verticalPosition: MatSnackBarVerticalPosition = 'top';
fileName= 'SpoorIT_Member_Report.xlsx';  


  constructor(public authService: AuthService,private http:HttpClient,private router: Router,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  	  		this.authService.IsAuthenticated = true;
  	  		this.getData();
  }


async getData() {

        const headers = new HttpHeaders()
    .set("Accept", "application/json");
    this.http.get(this.apiURL+"history.php",
      {headers})
    .subscribe(
      val => {
         let arra = [];  
    Object.keys(val).map(function(key){  
      arra.push({[key]:val[key]})  
      return arra;  
    });   

    if (arra[0]['status'] === 0) {
     //   this.result = JSON.stringify(val, null, 4);
        //console.log(this.result);
        var result_data = JSON.parse(JSON.stringify(val));

        this.plan_id = result_data.response.plan_id
   //     console.log("Plan" + this.plan_id);

        //this.plan_id = 2;
        for(var i = 0;i<result_data.response.user.length;i++){
       	var member_name = result_data.response.user[i]['name']
       	var member_tracking_id = result_data.response.user[i]['trackingid']

        this.user_arr.push({member_name:member_name, member_tracking_id:member_tracking_id}); 
         
         }
        if (this.plan_id <= 1) {
        	this.reports = false;
        	this.reportsImg = true;
        } else {
        	this.reportsImg = false;
        	this.reports = true;
        }
 //console.log("Plan" + this.plan_id + " " + this.reportsImg + " " + this.reports);
    

         }

      },
      response => {
        console.log("Get call in error", response);
      },
      () => {
        console.log("The Get observable is now completed.");
      }
      );
}

exportexcel()
    {
    		this.spinner = true
    	 if (this.loc_arr.length > 0) {

    		this.selecterror = false;
       let element = document.getElementById('excel-table'); 
      // console.log("table " + element)
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       XLSX.writeFile(wb, this.fileName);
       	this.spinner = false
   } else {
   	this.selecterror = true;
    	this.spinner = false
   }
			
    }

toPayments() {
	  this.router.navigate(['/payments']);

}

 getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}


onSubmit(dates,datee,trackid)
{
this.spinner = true
	if ((dates) && (datee) && (trackid)) {
		this.loc_arr = [];
	  this.suberror = false;
     this.locerror = false;
	 this.selecterror = false;
     this.maxerror = false;


	//console.log("Submit data " + dates + " " + datee + " " + trackid);   
	// var date_start = dates.split("/").join("-");
	// var date_end = datee.split("/").join("-");
     var max_day = this.getNumberOfDays(dates, datee);

     if (max_day < 15) {
	  var str = dates.split('/');
      var year = Number(str[2]);
      var date = Number(str[1]);
      var month = Number(str[0]);

      var date_start = year + "-" + month + "-" + date;

	   str = datee.split('/');
       year = Number(str[2]);
       date = Number(str[1]);
       month = Number(str[0]);
     var  date_end = year + "-" + month + "-" + date;

	//console.log(" Changed " + date_start + " " + date_end);

const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      this.http.post(this.apiURL+"history.php",
      {
        "id": trackid,
        "fromdate": date_start,
        "todate": date_end
      },
      {headers})
      .subscribe(
        val => {
        	this.spinner = false

          console.log("Post call successful value returned in body",  val);
        
   //console.log("Report " + JSON.parse(JSON.stringify(val)));
              var loc_result = JSON.parse(JSON.stringify(val))
          
    //       let arr = [];  
    //       Object.keys(val).map(function(key){  
    //         arr.push({[key]:val[key]})  
    //         return arr;  
    //       });   
          if (loc_result.status === 0) {
          	   //this.loc_arr.push({date:"Date",time:"Time",lat:"Latitude",lng:"Longitude"})
          	     	   if(loc_result.response.position.length <= 0) {
          	   	 this.locerror = true
          	   } else {
          	   	     this.loc_arr = [];
          	         this.loc_arr = loc_result.response.position
          	         if (this.loc_arr.length > 1) {
          	         	//  setTimeout(this.exportexcel, 3000);
          	         	//this.exportexcel()
          	         }
                }
          	  // console.log("Array length " + this.loc_arr.length);
        
          } else if (loc_result.status === 1){
          	this.locerror = true;
          } else {

          }


        },
        response => {
         this.spinner = false

				this._snackBar.open('Something went wrong. Please try again or Contact Administrator!', 'Close', {
					duration: 5000,
					horizontalPosition: this.horizontalPosition,
					verticalPosition: this.verticalPosition,
				});
             },
        () => {
          console.log("The Post observable is now completed.");
        }
        );
   } else {
      this.maxerror = true;
      this.spinner = false

   }
	} else {
		 this.suberror = true;
		 this.spinner = false
	}
}

}
