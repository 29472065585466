<h1 mat-dialog-title>Add Member <span style="float: right;cursor: pointer;"><i class="material-icons" (click)="onNoClick()">close</i> </span><br/>
<span style="font-size: 12px;">Give a unique member name here</span></h1>
<div mat-dialog-content>
 
  <mat-form-field>
    <mat-label>Enter name</mat-label>
    <input class="form-control" [(ngModel)]="data.animal">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Close</button>
  <button mat-button  [mat-dialog-close]="data.animal" cdkFocusInitial>Save</button>
</div>