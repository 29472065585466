import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

//import { User } from './user';
import { environment } from '../../environments/environment';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
	providedIn: 'root'
})


export class AuthService {
	API_URL: string = 'http://spoorit.evoxyz.tech/web_backend';
	headers = new HttpHeaders().set('Content-Type', 'application/json');
	currentUser = {};
	IsAuthenticated = false;
	apiURL = environment.apiUrl;
    plan_id: any;


	constructor(private httpClient: HttpClient,public router: Router,private _snackBar: MatSnackBar){}

	horizontalPosition: MatSnackBarHorizontalPosition = 'center';
	verticalPosition: MatSnackBarVerticalPosition = 'top';
  /*registerUser(): Observable<any> {

    return this.httpClient.post(`${this.API_URL}/signup.php`, user).pipe(
        catchError(this.handleError)
    )
}*/

login(mobile:number,password:string) {
	return this.httpClient.post<any>(this.apiURL+"login.php",  { mobile, password }, httpOptions)
	.subscribe((res: any) => {
		//console.log("LOGIN RESULT " + res);

		let arr = [];  
		Object.keys(res).map(function(key){  
			arr.push({[key]:res[key]})  
			return arr;  
		});
		

		if (arr[0]['status'] === 0) {
  if ((arr[1]['response']['token']) && (arr[1]['response']['userid']) && (arr[1]['response']['name'])) {
			localStorage.setItem('access_token', arr[1]['response']['token']);
			localStorage.setItem('user_id', arr[1]['response']['userid']);
			localStorage.setItem('user_name', arr[1]['response']['name']);
			//console.log("Token " + arr[1]['response']['token'] + " ID " + arr[1]['response']['userid']);
			//this.IsAuthenticated= true;
			this.plan_id = arr[1]['response']['plan_id']
			if (this.plan_id) {
			this.router.navigate(['/home']);
			} else {
			this.router.navigate(['/payments']);
			}

			} else {

				this._snackBar.open('Something went wrong. Please try again!', 'Close', {
					duration: 5000,
					horizontalPosition: this.horizontalPosition,
					verticalPosition: this.verticalPosition,
				});
			}
			
return true;
			} else {
				
				this._snackBar.open(arr[1]['response']['message'], 'Close', {
					duration: 5000,
					horizontalPosition: this.horizontalPosition,
					verticalPosition: this.verticalPosition,
				});
return true;
			}
		},
		response => {
		

				this._snackBar.open("Something went wrong.Please re-try!", 'Close', {
					duration: 5000,
					horizontalPosition: this.horizontalPosition,
					verticalPosition: this.verticalPosition,
				});
				 }

		)
}


getUserName() {
	return localStorage.getItem('user_name');
}

getUserID() {
	return localStorage.getItem('user_id');
}

getAccessToken() {
	return localStorage.getItem('access_token');
}

get isLoggedIn(): boolean {
	let authToken = localStorage.getItem('access_token');
	return (authToken !== null) ? true : false;
}

logout() {
	if (localStorage.removeItem('access_token') == null) {
		localStorage.removeItem('user_id');
		localStorage.removeItem('user_name');
		this.router.navigate(['/login']);
		this.IsAuthenticated= false;
	}
}


getUserProfile(id): Observable<any> {
	return this.httpClient.get(`${this.API_URL}/users/profile/${id}`, { headers: this.headers }).pipe(
		map((res: Response) => {
			return res || {}
		}),
		catchError(this.handleError)
		)
}

handleError(error: HttpErrorResponse) {
	let msg = '';
	if (error.error instanceof ErrorEvent) {
		// client-side error
		msg = error.error.message;
	} else {
		// server-side error
		msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
	}
	return throwError(msg);
}
}