import { Component, OnInit } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MapsAPILoader } from '@agm/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-geofence',
  templateUrl: './geofence.component.html',
  styleUrls: ['./geofence.component.css']
})
export class GeofenceComponent implements OnInit {

  constructor(private mapsAPILoader: MapsAPILoader,public authService: AuthService,private http: HttpClient, private router: Router) { }
apiURL = environment.apiUrl;
lat:any;
lng:any;
radius:any;
  latitude: number;
  longitude: number;
  zoom: number;
   arr = [];
   name: string;
   created_at: string;
   address:string;
  enabled_all:any;
  
    url  = 'https://spoorit.evoxyz.tech/assets/marker.svg';


  ngOnInit(): void {
  	this.authService.IsAuthenticated = true;

	        this.latitude = 28.4595;
        	this.longitude =  77.0266;
        	 this.zoom = 17;
  	this.getfences();
  }


getfences() {

const headers = new HttpHeaders().set("Accept", "application/json")

this.http.get(this.apiURL+"fetch_geofence.php", {headers})
.subscribe(
	val => {
		 let arr = [];  
    Object.keys(val).map(function(key){  
      arr.push({[key]:val[key]})  
      return arr;  
    });   

    if (arr[0]['status'] === 0) {
		//console.log("Response " + JSON.stringify(val,null,4))
        var result = JSON.parse(JSON.stringify(val))

        //console.log(result.response.geofence.length);
       
        for (var i = 0; i<result.response.geofence.length;i++) {
        var	check = result.response.geofence[i]['area'].replace( /[^\d.]/g, ' ' ).trim();
           var strArray = check.split(" ");
    
        this.lat = parseFloat(strArray[0]);
        this.lng = parseFloat(strArray[1]);
        this.radius = parseFloat(strArray[3]);
        this.name = result.response.geofence[i]['name'];
        this.created_at = result.response.geofence[i]['created_at'];
        this.address = result.response.geofence[i]['address'];
        this.enabled_all = result.response.geofence[i]['all_user'];
         if (this.enabled_all == 1) {
				 	var status_sign = '✔️';
				 } else {
				 	var status_sign = '❌'
				 }

        this.arr.push({lat: this.lat,lng:this.lng,radius:this.radius,name:this.name, created_at:this.created_at, address:this.address, all_users:status_sign});
        //console.log(this.lat + " " +  this.lng + " " + this.radius + " " + this.name);
    }
            this.latitude = strArray[0];
        	this.longitude =  strArray[1];
        //console.log(strArray[0] + " " + strArray[1] + " " + strArray[3]);
        //console.log(this.arr);
    }

	},
	response => {},
	() => {}

	);
}


scroll(id) {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  console.log(el);
  el.scrollIntoView();
}

openFence() {
	this.router.navigate(['/add-geofence']);
}

}
