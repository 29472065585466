import { Component, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { AuthService } from '../services/auth.service';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { OtpDialogComponent } from '../otp-dialog/otp-dialog.component';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /*emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    ]);*/
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    matcher = new MyErrorStateMatcher();
    apiURL = environment.apiUrl;

    form: FormGroup;
    public loginInvalid: boolean;
    public loginOtp: boolean;
    private formSubmitAttempt: boolean;
    public spinner: boolean;
    userotp: number;
    allDetails: boolean;
    mobileDigit: boolean;

    constructor(private router: Router, private fb: FormBuilder, public authService: AuthService,private httpClient: HttpClient,private _snackBar: MatSnackBar,public dialog: MatDialog) { }

    ngOnInit(): void {
      this.authService.IsAuthenticated = false;
      this.form = this.fb.group({

        mobile: ['', Validators.required],

        password: ['']

      });

      if (this.authService.isLoggedIn == true) {
        this.router.navigate(['/home'])
      }
      
    }


    async onSubmit () {


     this.loginInvalid = false;
     this.allDetails = false;
     this.formSubmitAttempt = false;
     this.spinner = false;
     this.mobileDigit = false;
     if (this.form.valid) {
      try {
       this.spinner = true;
       const mobile = this.form.get('mobile').value;
       const password = this.form.get('password').value;
       //console.log("hello" + mobile + " pass " + password);

       if ((password === '') || (mobile === '')) {
         this.loginInvalid = true;
         this.spinner = false;
       } else {
        if (mobile.toString().length == 10) {
          var bool = this.authService.login(mobile,password)
          if (bool) {
            this.spinner = false;
          }
          //this.spinner = false;
          // this.httpPostExample(mobile,password);  
          //this.router.navigate(['/home']);

        } else {
          this.mobileDigit = true;
          this.spinner = false;
        }
      }
      

    } catch (err) {
      this.loginInvalid = true;
    }

  } else {
   this.allDetails = true;
 }
}

to_register() {
	this.router.navigate(['/register']);
}

to_forgot() {
  this.router.navigate(['/forgot-password']);
}

hide =true;


openDialog()
{
  const mobile = this.form.get('mobile').value;

  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  if (mobile.toString().length == 10) {
    return this.httpClient.post<any>(this.apiURL+"login_otp.php",  { mobile}, httpOptions)
    .subscribe((res: any) => {
      console.log("LOGIN RESULT " + res);

      let arr = [];  
      Object.keys(res).map(function(key){  
        arr.push({[key]:res[key]})  
        return arr;  
      });

      if (arr[0]['status'] === 0) {

        const verify_mobile = arr[1]['response']['verify_mobile'];
        const dialogRef = this.dialog.open(OtpDialogComponent, {
          width: '400px',
          data: {  otp_mobile: verify_mobile}
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed' + result);
          this.userotp = result;
          console.log(this.userotp);
          if (this.userotp.toString().length === 6) {
          this.validateOTP(this.userotp,verify_mobile);
           } else {
              this._snackBar.open('Please try with correct OTP again!', 'Close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
           }  

          //window.location.reload();
        });  
      } else if (arr[0]['status'] === 1) {
       this.loginOtp = true;
     }

   })   

  } else {
    this.loginOtp = true;
  }

  //this.dialog.open(DialogExampleComponent);
}


validateOTP(otp,mobile) {
 const headers = new HttpHeaders()
 .set("Content-Type", "application/json");

 this.httpClient.post(this.apiURL+"login_otp.php",
 {
  "otp": otp,
  "mobile": mobile
},
{headers})
 .subscribe(
  val => {
    console.log("Verify otp call successful value returned in body",  val);

    let arr = [];  
    Object.keys(val).map(function(key){  
      arr.push({[key]:val[key]})  
      return arr;  
    });   

    if (arr[0]['status'] === 0) {

      if ((arr[1]['response']['token']) && (arr[1]['response']['userid']) && (arr[1]['response']['name'])) {

        localStorage.setItem('access_token', arr[1]['response']['token']);
        localStorage.setItem('user_id', arr[1]['response']['userid']);
        localStorage.setItem('user_name', arr[1]['response']['name']);
        console.log("Token " + arr[1]['response']['token'] + " ID " + arr[1]['response']['userid']);

        this.router.navigate(['/home']);
      } else {
       this._snackBar.open('Something went wrong. Please try again!', 'Close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
     }

   } else {


    this._snackBar.open(arr[1]['response']['message'], 'Close', {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }


},
response => {
  console.log("Post call in error", response);
},
() => {
  console.log("The Post observable is now completed.");
}
);
}


}
