
  <div class="container">


<div class="row">
  <div class="login_frame">

						<h2 style="color: #55a8a3;text-align: center;">Activate your plan</h2>
<mat-spinner *ngIf=spinner></mat-spinner>
			<mat-error *ngIf="error">Please check all fields and re-try!</mat-error>
      <mat-error *ngIf="moerror">Mobile number should be of 10 digits!</mat-error>

				<form class="example-form" [formGroup]="form" (ngSubmit)=submit() >

       <h3 style="color: #55a8a3;">Billing Information</h3>
    	<div class="col-md-12">
    		<div class="row">
    	<mat-form-field class="example-full-width">
      <mat-label>Name</mat-label>
      	<input matInput class="custom-input"  type="text" formControlName="username" >
            <span matPrefix><i class="material-icons">account_circle</i> </span>

    </mat-form-field>

<!--     		<div class="col-md-6">
    
    	<mat-form-field class="example-full-width">
      <mat-label>Last Name</mat-label>
		 <input matInput class="custom-input" placeholder="Last name" formControlName="lastname" type="text" >
    </mat-form-field>
</div> -->
</div>
</div>


      <div class="col-md-12">
        <div class="row">
      <div class="col-md-6">
      <mat-form-field class="example-full-width">
      <mat-label>Email</mat-label>
          <input type="email" class="custom-input" matInput value="{{data.email}}" formControlName="useremail">
           <span matPrefix><i class="material-icons">email</i> </span>
    </mat-form-field>
</div>
        <div class="col-md-6">
    
      <mat-form-field class="example-full-width">
      <mat-label>Mobile</mat-label>
          <input matInput class="custom-input"  placeholder="Mobile" type="number" formControlName="mobile" minlength="10" maxlength="10" required>
<!--            <span matPrefix><i class="material-icons">call</i> </span>
 -->    </mat-form-field>
</div>
</div>
</div>

     <div class="col-md-12">
      <mat-form-field style="display: initial;">
      <mat-label>Add billing address (Optional)</mat-label>
          <textarea class="custom-input" matInput placeholder="Add billing address, if any" formControlName="bill_add" > </textarea>
                     <span matPrefix><i class="material-icons">home</i> </span>
    </mat-form-field>
</div>

       <h3 style="color: #55a8a3;">Choose billing cycle</h3>

    <mat-radio-group  aria-labelledby="example-radio-group-label"  class="example-radio-group" formControlName="plan" (change)=selectPlan($event)>
          <mat-radio-button class="example-radio-button"  [value]="499" checked>    Annual payment</mat-radio-button>
          <mat-radio-button class="example-radio-button"  [value]="699">    Quaterly payment </mat-radio-button>
      </mat-radio-group>



       <h3 style="color: #55a8a3;">Order Summary</h3>

       <h4>SPOOR-L <strong><span style="float: right;">Rs {{choosen_plan}}</span></strong><br/>
        <!-- <span style="float: right;font-size: 11px;">Future Payments: Rs {{choosen_plan}} for each month</span> -->
       </h4>
        <p>GST (inclusive in Total)<span style="float: right;">18%</span></p>
       <h4 >Total <span style="float: right;" >Rs {{total}}</span></h4>
       <p></p>
       <br/>
			

                  <button mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;margin: 0 auto;display: block;" class="btn-lg"> Activate </button>

		</form>

	</div>



	</div>
</div>
