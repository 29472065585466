<section class="main-view gmap">
    <div class="banner">
              <div class="main-padding">
    <div class="container">
<div class="row">

 <h1 class="mat-h1 for-align">Choose your plan</h1>
 <h3 class="for-align" style="color: #fff;">Please use BHIM or PayTM UPI to make payment and activate your plan.</h3>
</div>
</div>
</div>
</div>

 <div class="overlaying">
        <div class="main-padding">

                     <mat-spinner *ngIf="spinner"></mat-spinner>
  <div class="container">
<div class="row" style="margin-top: 4%;">   

      <mat-card *ngFor="let plans of plan_data" class="col-md-3 text-center" >
        <form>
            <input type="hidden" #plan_id name="plan_id" value="{{plans.plan_id}}">
            <div class="plan"> {{plans.plan_name}}</div><br/>
            <img [src]="plans.plan_image" class="img-responsive" alt="">
            <div ><span class="price">₹{{plans.plan_amount}}</span> / MO.</div><br><!-- 
            <div class="features"> ₹ 0</div> <br> -->
          <mat-chip-list aria-label="Fish selection">
          <mat-chip  style="margin:0 auto;margin-bottom: 10%;cursor: pointer;" class="track btn-lg" (click)="Subscribe(plan_id.value)"> <span>Get it Now!</span></mat-chip>
          </mat-chip-list>   
            <div class="benefits">
                <ul *ngFor="let feature of plans.plan_feature; index as i">
                    <li [ngClass]="{meta:i===0}">{{feature}}</li>
                    <!-- 
                <li> Live tracking for 0 to 3 user</li>
                <li> Free</li> -->
                </ul>
           </div>
         </form>
                
        </mat-card>


        </div>


  </div>
</div>
</div>
</section>