import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { NavigationComponent } from './navigation/navigation.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { DisplayUserDashboardComponent } from './display-user-dashboard/display-user-dashboard.component';
import { AdduserDialogComponent } from './adduser-dialog/adduser-dialog.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { OtpDialogComponent } from './otp-dialog/otp-dialog.component';

import { AuthInterceptor } from './_helpers/auth.interceptor';
import { ShowGoogleMapComponent } from './show-google-map/show-google-map.component';

import { AgmCoreModule } from '@agm/core';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { AddGeofencesComponent } from './add-geofences/add-geofences.component';
import { GeofenceComponent } from './geofence/geofence.component';
import { EdituserDialogComponent } from './edituser-dialog/edituser-dialog.component';
import { ShowAlertComponent } from './show-alert/show-alert.component';

import { ChartsModule } from 'ng2-charts';
import { PaymentsComponent } from './payments/payments.component';
import { ReportsComponent } from './reports/reports.component';
import { CheckoutComponent } from './checkout/checkout.component';


@NgModule({
  declarations: [
    AppComponent,
    SubscriptionComponent,
    LoginComponent,
    MyDashboardComponent,
    NavigationComponent,
    ManageUserComponent,
    DisplayUserDashboardComponent,
    AdduserDialogComponent,
    SignUpComponent,
    OtpDialogComponent,
    ShowGoogleMapComponent,
    EmailVerifyComponent,
    AddGeofencesComponent,
    GeofenceComponent,
    EdituserDialogComponent,
    ShowAlertComponent,
    PaymentsComponent,
    ReportsComponent,
    CheckoutComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
   MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatButtonToggleModule,
  MatTreeModule,
  MatBadgeModule,
  MatGridListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatSlideToggleModule,
  FormsModule,
  ReactiveFormsModule,
  LayoutModule,
  ShareModule,
  ShareIconsModule,
  ShareButtonsModule,
  ClipboardModule,
  MatDialogModule,
  ChartsModule,
  AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA_u6RkoqkWcRrn0yxa0zoC3E0SXDMeczU',
      libraries: ['places']
    })
  ],
  providers: [ {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
  entryComponents: [AdduserDialogComponent]
})
export class AppModule { }
