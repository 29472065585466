<section class="main-view">
  <div class="banner">
	<div class="container">
	
 <h1 class="mat-h1">Track User
    
   </h1>
</div>
</div>

 <div class="overlaying">
        <div class="main-padding">
        	<div class="container">
		<!-- <mat-grid-list cols="4" >

			<mat-grid-tile [colspan]="3" [rowspan]="2"> -->
<div class="row">
				<mat-card class="col-md-12">
				
					<mat-card-content >

						<!--<agm-map
						[latitude]="lat"
						[longitude]="lng"
						[zoom]="13"
						(mapClick)="addMarker($event.coords.lat, $event.coords.lng)"
						>-->
 
  <div id="map" >
						<agm-map
						
						[latitude]="lat"
						[longitude]="lng"
						[zoom]="18"
						>
						<!-- <agm-marker
						*ngFor="let marker of marker_result"
						[latitude]="marker.lat"
						[longitude]="marker.lng"
						[opacity]="marker.alpha"
						[markerDraggable]="true"
						[iconUrl]="url"
						(markerClick)="selectMarker($event)"
						> -->
						<agm-marker
						*ngFor="let marker of marker_result"
						[latitude]="marker.lat"
						[longitude]="marker.lng"
						[iconUrl]="url"

						>
						<agm-info-window #infowindow>
							<div>
								<p> <strong> {{marker.user}} </strong></p>
								<p><strong>Location </strong><br/> {{marker.location}}</p>
								<p><strong>Last updated time</strong><br/> {{marker.time}}</p>
							</div>
						</agm-info-window>
					</agm-marker>
     <!--  <agm-rectangle
        [north]="max('lat')"
        [east]="max('lng')"
        [south]="min('lat')"
        [west]="min('lng')"
      >
  </agm-rectangle> -->
</agm-map>
</div>
<!-- <p *ngIf="selectedMarker">
	Lat: {{ selectedMarker.lat }} Lng: {{ selectedMarker.lng }}
</p> -->

</mat-card-content>
</mat-card>

<!-- </mat-grid-tile>

<mat-grid-tile [colspan]="1" [rowspan]="2"> -->

<!-- 	<mat-card class="col-md-4">
		<mat-card-header>
			<mat-card-title style="color: #fff;">
				Details 
			</mat-card-title>
		</mat-card-header>
		<mat-card-content class="dashboard-card-content">

   <table mat-table  [dataSource]="details"  class="mat-elevation-z8">


  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef> S.No. </th>
<td mat-cell *matCellDef="let element"> {{element.position}} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name</th>
 <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
<td mat-cell *matCellDef="let element"> {{element.status}} 
 
  </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

		</mat-card-content>
	</mat-card> -->

<!-- </mat-grid-tile>

</mat-grid-list> -->

</div>
</div>
</div>
</div>

</section>
