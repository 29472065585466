import { Component , ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import {MatSidenav} from '@angular/material/sidenav';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})


export class NavigationComponent  implements OnDestroy{
@ViewChild('sidenav') sidenav: MatSidenav;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
    );

 mobileQuery: MediaQueryList;

private _mobileQueryListener: () => void;

  constructor(private breakpointObserver: BreakpointObserver, private snackBar: MatSnackBar,public authService: AuthService,private router: Router,changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);


  }

Check_value: String;
events: boolean;
e: any;

  sidenavWidth = 15;
  ngStyle: string;

  ngOnInit(): void {
   
 //console.log("sidenavWidth" + this.sidenavWidth)

    if(!this.mobileQuery.matches) {
      this.events = false;
    }
   // console.log("Events " + this.events);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

open() {
   if(this.mobileQuery.matches) {
    this.sidenavWidth = 15;
   }
  var test = this.sidenav.toggle();
  console.log(test);
  if (this.events) {
  this.events = false;
}
  //   if (this.events) {
  //      if(!this.mobileQuery.matches) {
  //     this.events = false;
  //   }
  // }


//  else {
//     this.events = true
// }
 console.log("Events " + this.events);

}
close() {
    // this.events = true;
    if(this.mobileQuery.matches) {
      this.sidenav.toggle();
    } else if (this.sidenavWidth  == 4) {
         this.sidenavWidth = 15;
    } else {
    this.sidenavWidth = 4;
    }
//    this.sidenav.toggle();
  //     if(this.events) {
  //   this.events = false;
  // } else {
  //   if(!this.mobileQuery.matches) {
  //       this.events = true;
  //     }
  // }
   console.log("Events " + this.events);

  }

goUser() {
  this.router.navigate(['/user']);
     if(this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
}

goHome() {
this.router.navigate(['/home']);
   if(this.mobileQuery.matches) {
      this.sidenav.toggle();
      }
// this.sidenav.close();
//   this.events = true;
}

goGeofence() {
  this.router.navigate(['/geofence']);
     if(this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
}
goAlerts() {
  this.router.navigate(['/alerts']);
     if(this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
}

goPayments() {
  this.router.navigate(['/payments']);
     if(this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
}

goReports() {
    this.router.navigate(['/reports']);
     if(this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
}

goTnc() {
     window.open("https://evoxyz.com/tnc.html", "_blank");

}

goPrivacy() {
       window.open("https://evoxyz.com/privacy-policy-spoorit.html", "_blank");

}

goFaq() {
       window.open("https://spoorit.in/faq.html", "_blank");
}

openSnackBar() {
  this.snackBar.open('Navigation disabled', 'Undo', {
    duration: 3000
  });
}

logout() {
  this.authService.logout();
}

}
