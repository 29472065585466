import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';
import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';


export interface UserElement {
  name: string;
  position: number;
  trackingid: number;
  share: string;
  action: any;
  status: any;
}

const USER_DATA: UserElement[] = [
{position: 1, trackingid: 688192, name: 'Pankhur', share: 'https://www.evoxyz.com/spoorit/688192', status: '', action: ''},
];

@Component({
  selector: 'app-display-user-dashboard',
  templateUrl: './display-user-dashboard.component.html',
  styleUrls: ['./display-user-dashboard.component.css']
})

export class DisplayUserDashboardComponent implements OnInit {

 apiURL = environment.apiUrl;
 //value = 'https://www.evoxyz.com/spoorit/688192';
 displayedColumns: string[] = ['position', 'trackingid', 'name', 'mobile', 'share', 'last_seen', 'status'];
 dataSource = USER_DATA;
 result: any;
  currentItem= 'Television';

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

 constructor(private http:HttpClient,public authService: AuthService,private clipboard: Clipboard,private _snackBar: MatSnackBar) { }

 ngOnInit(): void {

  const headers = new HttpHeaders()
  .set("Accept", "application/json");
  const user_id = this.authService.getUserID();
  const logged_user = this.authService.getUserName();
  this.http.get(this.apiURL+"users.php?userid="+user_id,
    {headers})
  .subscribe(
    val => {

      //console.log("Get call successful value returned in body", val);
      this.result = val;
      //let response = res.body;
      //this.countries = response.data.children;
      //console.log("All users" + val);
    },
    response => {
     // console.log("Get call in error", response);
    },
    () => {
      console.log("The Get observable is now completed.");
    }
    );




}


copyLink(value) {
    this.clipboard.copy(value);
      this._snackBar.open('Copied to clipboard!', 'Close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

  }


}
