<div class="container">
	<div class="row">
		
<h2 mat-dialog-title>Edit Member</h2>
<mat-dialog-content class="mat-typography">
<form (ngSubmit)="onSubmit(kid_name.value,kid_mobile.value)">
	<div class="col-md-12">
	<mat-form-field >
      <mat-label>Change name</mat-label>
		 <input matInput value="{{data.kid_name}}" type="text" #kid_name name="kid_name" style="border:2px solid #eee;padding: 20px;">
    </mat-form-field>
</div>
<mat-error *ngIf="error">Name can not be empty!</mat-error>


<div class="col-md-12">
<mat-form-field appearance="fill">
  <mat-label>Change Geofence List</mat-label>
  <mat-select [(value)]="selected"  [disabled]="disableSelect.value" multiple>
    <mat-option *ngFor="let fence_data of fence_arr" value="{{fence_data.fence_id}}" >{{fence_data.fence_name}}</mat-option>
  </mat-select>
</mat-form-field>
<h1 *ngIf="data.kid_fence"> Already assigned fence</h1>
<p *ngFor="let fence of data.kid_fence" style="color: #55a8a3;"> {{fence.name}}</p>
</div>
<div class="col-md-12">
<mat-error *ngIf="plan_error">For Basic Plan, mobile number can be added but can not be edited.</mat-error>

  <mat-form-field>
        <mat-label>Change mobile number</mat-label>

  <input matInput value="{{data.kid_mobile}}" type="number" #kid_mobile name="kid_mobile" style="border:2px solid #eee;padding: 20px;">

</mat-form-field>
</div>

<!-- <p>
  <mat-checkbox [formControl]="disableSelect">Disable Geo-fence</mat-checkbox>
</p> -->
<!-- <p><span style="color: green;">Selected Geofence:</span> {{selected}}</p>
 -->
			<button mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;">Proceed</button>

</form>
</mat-dialog-content>

</div>
</div>