import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from '../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


declare var Razorpay: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  form: FormGroup;
  choosen_plan = 499;
  error: boolean;
  apiURL = environment.apiUrl;
  total = '7066 for 12 months billed annually';
  annual: any;
  total_amount: number;
  quat:number;
  spinner: boolean;
  admin_name: any;
  moerror: boolean;




  constructor(public dialogRef: MatDialogRef<CheckoutComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder,private router: Router, private http: HttpClient,private _snackBar: MatSnackBar,private ngZone: NgZone) { }

 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';


  ngOnInit(): void {
  	//console.log("Data " + this.data.email);
     this.admin_name = this.data.name;
  	  this.form = this.fb.group({
    username: ['', Validators.required],
    useremail: ['', Validators.email],
    mobile: ['', Validators.required],
    plan: [''],
    bill_add:['']
  });

  	  this.form.get('username').setValue(this.data.name)
  	  this.form.get('useremail').setValue(this.data.email)  	  
  	  this.form.get('mobile').setValue(this.data.mobile)
  	  this.form.get('plan').setValue(499)
  }


  payWithRazor(order_id,name,email,phone,amount,type) {

    const options: any = {
      key: 'rzp_live_1NIgMKHdVUFdbr',
      amount: amount,
      currency: 'INR',
      name: "Evoxyz Technology", // company name or product name
      description: email,  // product description
      image: 'https://cdn.razorpay.com/logos/GkPpA8GxirdorE_medium.png', // company logo or product image
      order_id: order_id,
      prefill: {
      	name: name,
      	email: email,
      	contact: phone
      },
      modal: {
        escape: false,
        confirm_close: true
      },
      notes: {
      },
      theme: {
        color: '#55a8a3'
      }
    };

    options.handler = ((response, error) => {
      options.response = response;
     // console.log(response);


      const {razorpay_payment_id: razor_payment_id, razorpay_signature: razor_sign, razorpay_order_id: razor_order_id } = response

      //console.log("Pay " + razor_payment_id + razor_sign + razor_order_id)

      this.verify(order_id,this.data.plan_id,razor_payment_id,razor_sign,razor_order_id,type);

    });

    options.modal.ondismiss = (() => {
      console.log('Transaction cancelled.');
    });

     const rzp = new Razorpay(options);
     rzp.open();
  }

verify(order_id,plan_id,razor_payment_id,razor_sign,razor_order_id,type) {

this.spinner = true;
      const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      this.http.post(this.apiURL+"verify_payment_new.php",
      {
        "plan_id": plan_id,
        "rorder_id": razor_order_id,
        "pay_id": razor_payment_id,
        "rsign": razor_sign,
        "order": order_id,
        "type" : type
      },
      {headers})
      .subscribe(
        val => {
       
 
           this.spinner=false;
            var result = JSON.parse(JSON.stringify(val));

          if (result.status == 1) {
            this._snackBar.open(result.response.message, 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          } else if (result.status == 0) {

            this._snackBar.open(result.response.message, 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });

      		this.ngZone.run(() => {
        	this.dialogRef.close();
        	})

 	       this.router.navigate(['/home']);
        }

        },
        response => {

        },
        () => {

        }
        );

  }

submit() {

  const bill_username = (this.form.get('username').value);
  const bill_email = (this.form.get('useremail').value);
  const bill_mobile = (this.form.get('mobile').value);
  const bill_plan = (this.form.get('plan').value);
  //console.log("Form " + bill_username + " " + bill_email + " " + bill_mobile + " " + bill_plan);
  this.error= false;
  this.moerror=false;
  if ((bill_username) && (bill_email) && (bill_mobile) && (bill_plan)) {

  if (bill_mobile.toString().length == 10) {
this.spinner = true;

    	     const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      this.http.post(this.apiURL+"subscribe_new.php",
      {
        "plan_id": this.data.plan_id,
        "bill_name": bill_username,
        "bill_mobile": bill_mobile,
        "bill_email": bill_email,
        "bill_plan": bill_plan
      },
      {headers})
      .subscribe(
        val => {
          //console.log("Post call successful value returned in body",  val);
                      	this.spinner = false;

            var result = JSON.parse(JSON.stringify(val));
			//console.log(result)
          if (result.status == 1) {
            //console.log("show snackbar");

            this._snackBar.open(result.response.message, 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          } else if (result.status == 0) {

            const { order: order_id, status: order_status, amount: order_amount } = result.response.data

            //console.log("Order " + order_id + " " + order_status + " " + order_amount)

            if (order_id) {

			var plan_type = 3
  			if (bill_plan == 499) {
          		plan_type = 12
          	} 

            this.payWithRazor(order_id,bill_username,bill_email,bill_mobile,order_amount,plan_type);
             
             } else {
             this._snackBar.open("Please refresh / re-try, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
             }


          } else {

          }
        },
        response => {
        	     this.spinner = false;
         this._snackBar.open("Please refresh / re-try, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
         // console.log("Post call in error", response);
         // this.spinner = false;
        },
        () => {
          console.log("The Post observable is now completed.");
        }
        );
  } else {
  		this.moerror = true;
  }
} else {
	this.error = true;
}

}

selectPlan(event) {
	if (event.value) {
		this.choosen_plan = event.value;
		if (event.value == 499) {
			this.annual = 499 * 12;
			this.total_amount =  Math.round(this.annual + (this.annual * .18));
			this.total = this.total_amount + " for 12 months billed annually";
		} else {
			this.quat = 699 * 3;
			this.total_amount =  Math.round(this.quat + (this.quat * .18));
			this.total = this.total_amount + " billed quaterly";
		}
	}
}

  test(value) {
  	//console.log("Change value " + value);

  }

}
