<section class="main-view gmap">
	<div class="banner">
		      <div class="main-padding">
	<div class="container">
<div class="row">

 <h1 class="mat-h1">Reports</h1>
<mat-error *ngIf="suberror">Please re-select all fields!</mat-error>
<p *ngIf="reports" style="color: #fff;">You can View / export 15 days of data at once. For more detailed reports contact us at support@evoxyz.com</p>

</div>
</div>
</div>
</div>
<div class="overlaying">
	      <div class="main-padding">
	<div class="container">
		<div class="row">
	<div class="col-md-12">
		<div *ngIf="reportsImg">
	<img [src]="img_url" (click)="toPayments()">
</div>
		
			<form class="row" *ngIf="reports" (ngSubmit)="onSubmit(date_start.value,date_end.value,member_track.value)">
			<div class="col-md-3">
	     	<mat-form-field appearance="fill">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker" >
    <input matStartDate #date_start name="date_start" placeholder="Start date">
    <input matEndDate #date_end name="date_end" placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker maxRange=15  #picker></mat-date-range-picker>
</mat-form-field><br/>
</div>

<div class="col-md-3">
<mat-form-field appearance="fill">
  <mat-label>Select Member</mat-label>
  <mat-select #member_track name="member_track">
<mat-option *ngFor="let member_data of user_arr" [value]="member_data.member_tracking_id"> {{member_data.member_name}} </mat-option>  
</mat-select>
</mat-form-field>
</div>
<div class="col-md-1">
<button class="track btn-lg" mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;">Show</button>
</div>
</form>
<mat-card style="background: #fff;" class="col-md-12" *ngIf="reports">

      <mat-card-header>
        <mat-card-title>
          Detailed Report <!-- 
          <a style="cursor: pointer;float: right;" >
  <mat-icon> cloud_download</mat-icon>
</a> -->
                  <button mat-raised-button *ngIf="loc_arr" style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;float: right;" class="btn-lg" (click)="exportexcel()">Export</button>

       </mat-card-title>   <br/>      
     </mat-card-header>
     <mat-card-content>
      <div class="table-responsive">
        <table class="table table-striped" id="excel-table">
          <tr>
            <td>S.No</td>
            <td>Date</td>
            <td>Time</td>
            <td>Latitude</td>
            <td>Longitude</td>
  </tr>
          <tbody>


            <tr *ngFor = "let loc of loc_arr; index as i">
              <td>{{i+1}}</td>
              <td>{{loc.date}}</td>
              <td>{{loc.time}}</td>
              <td>{{loc.lat}}</td>
              <td>{{loc.lng}}</td>
          
          </tr>
        </tbody>
      </table>
    </div>
         <mat-spinner *ngIf="spinner"></mat-spinner>
         <mat-error *ngIf="locerror">No data found. Please choose other dates/range or member!!</mat-error>
         <mat-error *ngIf="selecterror">Seems no data to export. Please view report by clicking on Show first!!</mat-error>
         <mat-error *ngIf="maxerror">Selected date range can't be more than 15 days.</mat-error>

  </mat-card-content>

</mat-card>






	     </div>

</div>
</div>
</div>
</div>
</section>
