<div [class.example-is-mobile]="mobileQuery.matches">
<mat-sidenav-container class="sidenav-container example-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
  <!--<mat-sidenav style="background-color: #3d453f;" #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">-->
       <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" [opened] ="mobileQuery.matches ? false : true" [ngStyle]="{ 'width.em': sidenavWidth }" *ngIf = "authService.IsAuthenticated">
    <mat-toolbar>
      
            <div class="company">
              <div class="logo" [style.width]="sidenavWidth > 6 ? '25%' : '100%'"></div>
              <span  style="width: 50%;" *ngIf="sidenavWidth > 6">
                <img class="logo_name img-responsive" src="https://spoorit.evoxyz.tech/assets/spoorit.png">
              </span>

            </div>

    </mat-toolbar>
    <mat-divider></mat-divider>
   <!--  <mat-nav-list>
      <a mat-list-item (click)="openSnackBar()"> General Settings <i class="material-icons">settings</i> </a>
    </mat-nav-list> -->
      <mat-divider></mat-divider>
    <mat-nav-list>
      <a mat-list-item (click)="goHome()"><i class="material-icons">dashboard</i> 
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Dashboard</span>
       
      </a>

      <a mat-list-item (click)="goAlerts()"><i class="material-icons">notifications</i> 
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Alerts</span>
        
      </a>

      <a mat-list-item (click)="goUser()"><i class="material-icons">supervisor_account</i>
     
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Members</span>
        
      </a>

      <a mat-list-item (click)="goGeofence()"><i class="material-icons">fence</i> 
       
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Geofences</span>
        
      </a>

      <a mat-list-item (click)="goPayments()"><i class="material-icons">payment</i> 
       
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Payments</span>
        
      </a>

      <a mat-list-item (click)="goReports()"><i class="material-icons">analytics</i> 
       
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Reports</span>
        
      </a>

      <a mat-list-item (click)="goFaq()"><i class="material-icons">help</i>
         
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Faq</span>
        
      </a>

     <!--  <a mat-list-item (click)="openSnackBar()"><i class="material-icons">settings</i>   
     
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Settings</span>
        
      </a> -->

      <a mat-list-item (click)="logout()"><i class="material-icons">logout</i> 
         
        
            <span class="nav-text" *ngIf="sidenavWidth > 6"> Logout</span>
        
      </a>

      
  <!--<p><button mat-button (click)="sidenav.toggle()">Toggle</button></p>-->
    </mat-nav-list>
     <mat-divider></mat-divider>

   
    <div class="example-footer" style="color: #fff;padding: 14px;width: fit-content;">
        <mat-nav-list>
     <a mat-list-item (click)=goTnc()>
      <span *ngIf="sidenavWidth > 6" class="nav-text">
      Terms and Conditions
      </span>

    </a>

     <a mat-list-item (click)=goPrivacy()><span *ngIf="sidenavWidth > 6" class="nav-text">Privacy Policy</span></a>

     </mat-nav-list>
     <mat-chip-list aria-label="Fish selection"  *ngIf="sidenavWidth > 6" >
       <mat-chip  class="track btn-lg" (click)=goPayments()>Upgrade</mat-chip>
      </mat-chip-list>
       
       
      <p><i  *ngIf="sidenavWidth > 6" class="material-icons arrow" style="cursor: pointer;" (click)="close()">keyboard_arrow_left</i></p>

      <p><i  *ngIf="sidenavWidth < 6" class="material-icons" style="cursor: pointer;" (click)="close()">keyboard_arrow_right</i></p>

      </div>

  </mat-sidenav>
  <mat-sidenav-content  [style.marginLeft.px]="sidenavWidth > 6 && !mobileQuery.matches  ? (authService.IsAuthenticated ? 200 : 0) : (mobileQuery.matches ? 0 : 50)" >

 <!-- 
    <mat-sidenav-content  [style.marginLeft.px]="sidenavWidth > 6 && !mobileQuery.matches  ? 200 : 0 " > -->

<!--     <mat-icon style="color: #fff;float: left;position: relative;top:10px;left: 5%;cursor: pointer;" *ngIf="events" (click)="open()" class="sidenav-menu">menu</mat-icon>
 -->     
     <mat-icon style="color: #fff;float: left;position: relative;top:10px;left: 5%;cursor: pointer;margin-bottom: 2%;z-index: inherit;" *ngIf="mobileQuery.matches && authService.IsAuthenticated ? true : false || events"  (click)="open()" class="sidenav-menu">menu</mat-icon>

        <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
