import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";

import { HttpClient,HttpHeaders} from '@angular/common/http';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import {FormControl} from '@angular/forms';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-edituser-dialog',
  templateUrl: './edituser-dialog.component.html',
  styleUrls: ['./edituser-dialog.component.css']
})
export class EdituserDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EdituserDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar,private http: HttpClient) { }

apiURL=environment.apiUrl;
private user_fence: any;
private fence_name: string;
 fence_arr = [];
private fence_kid: string;
private fence_id: number;
 error: boolean;
 plan_error: boolean;
selected = "";
checked: false;
admin_planID:number;

 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

disableSelect = new FormControl(false);

  ngOnInit(): void {

//console.log(this.data.fence + " " + this.data.kid_name + " " + this.data.kid_mobile);
this.admin_planID = this.data.admin_planid;

//console.log("Plan " + this.admin_planID);

if (this.admin_planID == 1) {
    this.plan_error = true;
 }
this.user_fence = this.data.fence.length;
    if (this.user_fence > 0 ) {
    for (var i = 0; i<this.user_fence;i++) {

    	this.fence_name = this.data.fence[i]['name'];
    	this.fence_id = this.data.fence[i]['id'];


    	this.fence_arr.push({fence_name: this.fence_name, fence_id: this.fence_id});
    }
//console.log(this.fence_arr);

}

  }



 async onSubmit (value,mobile) {
this.error = false;
this.plan_error = false;
var disable_geo = JSON.parse(JSON.stringify(this.disableSelect))

// console.log(" Submit Fence " + this.selected + " User name " + value + " User id " + this.data.kid_id + " " + mobile + " " + disable_geo.value);
 console.log("Plan " + this.admin_planID);
 
 if (this.admin_planID == 1) {
    if (this.data.kid_mobile) {
       if (this.data.kid_mobile != mobile) {
          mobile = "";   
       }
    }
 }

var kid_name = value.trim();
if (kid_name.length > 0) {
const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      this.http.put(this.apiURL+"users_new.php",
      {
        "name": value,
        "fence_ids": this.selected,
        "mobile": mobile,
        "id": this.data.kid_id
      },
      {headers})
      .subscribe(
        val => {
          console.log("Post call successful value returned in body",  val);
        
            
          let arr = [];  
          Object.keys(val).map(function(key){  
            arr.push({[key]:val[key]})  
            return arr;  
          });   
          if (arr[0]['status'] === 0) {
           // console.log("show snackbar");
            this._snackBar.open(arr[1]['message'], 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          } else {
    this._snackBar.open(arr[1]['message'], 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }

        },
        response => {
          console.log("Post call in error", response);
             },
        () => {
          console.log("The Post observable is now completed.");
        }
        );
 } else {
 	this.error = true;
 }


}

}
