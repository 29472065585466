
<div class="main-view">
	<div class="banner">
		      <div class="main-padding">

		<div class="container">
			<div class="row">
				<h1 class="mat-h1 for-align">Geofences</h1>

			</div>
		</div>
	</div>
</div>
	<div class="overlaying">
		      <div class="main-padding">
		<div class="container">
			<div class="row">
				<mat-card style="background: #fff;"  class="col-md-12">

					<mat-card-header>
						<mat-card-title>
							Existing Geofence 
							<span style="float: right;margin-bottom: 1%;">

								<mat-chip-list class="for-align" aria-label="Fish selection">
									<mat-chip (click)="openFence()" class="track"> <i class="material-icons">fence</i>  <span style="padding-left: 5px;">Add Geofence</span></mat-chip>

								</mat-chip-list>
							</span>
						</mat-card-title>					
					</mat-card-header>

					<mat-card-content>
						<div class="table-responsive">
							<table class="table table-striped">
								<thead>
									<th>S.No</th>
									<th>Name</th>
									<th>Address</th>
									<th>Radius</th>
									<th>All users</th>
<!-- 									<th>Action</th>
 -->									<th>Created on</th>
								</thead>
								<tbody>
									<tr *ngFor="let geo of arr; index as i">
										<td>{{i+1}}</td>
										<td><a (click)="scroll(i)" style="cursor: pointer;">{{geo.name}}</a></td>
										<td>{{geo.address}}</td>
										<td>{{geo.radius}}m</td>
										<td>{{geo.all_users}}</td>
									<!-- 	<td> <label>Disable  </label> 
											<mat-slide-toggle ariaLabel="Close" color="primary" >Enable</mat-slide-toggle>
										</td> -->
										<td>{{geo.created_at}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</mat-card-content>

				</mat-card>

			</div>
		</div>

		<div class="container">
			<div class="row" style="margin-top: 4%;">
				<mat-card class="col-md-12">

					<mat-card-header>
						<mat-card-title>
							Enabled Geofences  
						</mat-card-title>
					</mat-card-header>

					<mat-card-content >

						<div id="map" >
							<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
								<agm-marker
								*ngFor="let data of arr"
								[latitude]="data.lat"
								[longitude]="data.lng"
								[markerDraggable]="false"
								[iconUrl]="url"
								>

								<agm-info-window [disableAutoPan]="true">
									<strong>{{data.name}}</strong>
								</agm-info-window>
							</agm-marker>

							<agm-circle *ngFor="let data of arr; index as i" id="{{i}}" [latitude]="data.lat" [longitude]="data.lng" [radius]="data.radius" [fillColor]="'red'" [circleDraggable]="false" [editable]="false" matTooltip="Click to copy">
							</agm-circle>

						</agm-map>
					</div>

				</mat-card-content>

			</mat-card>
			
		</div>
	</div>
</div>
</div>
</div>
