import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.css']
})
export class EmailVerifyComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,private httpClient: HttpClient,private _snackBar: MatSnackBar, private fb: FormBuilder) { }

apiURL= environment.apiUrl;
email_token: string;
editPass: boolean;
forgotPass: boolean;
provideEmail: boolean;
passwordnotmatch: boolean;
providePass: boolean;
passwordLength: boolean;

 form: FormGroup;
 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

  ngOnInit(): void {

  	   this.form = this.fb.group({

        email: ['', Validators.required],
        pass1: [''],
        pass2: ['']
      });
	// this.editPass = false;
	// this.forgotPass = false;
  	 this.activatedRoute.params.subscribe(paramsId => {
  	 	console.log("token " + paramsId.token);
        this.email_token = paramsId.token;
        console.log(this.email_token);
        if (this.email_token) {
        if (this.email_token.length === 20) {
        	this.editPass = true;
        } else {
            this.forgotPass = true;
        } 
    } else {
        	this.forgotPass = true;
        }
    });
  	   //this.token = this.route.snapshot.paramMap.get('email_token');

  }



  resetPass() {

this.providePass = false;
this.passwordnotmatch = false;
this.passwordLength = false;
 const user_pass1 = this.form.get('pass1').value;

 const user_pass2 = this.form.get('pass2').value;

console.log(" pass1 " + user_pass1 + " pass2 " + user_pass2);
	

 if ((user_pass1 === '')  || (user_pass2 === '')) {

this.providePass = true;

} else {
if (user_pass1 === user_pass2) {

if (user_pass1.length <= 20) {

 const headers = new HttpHeaders()
 .set("Content-Type", "application/json");

 this.httpClient.post(this.apiURL+"email_verify.php",
 {
  "token": this.email_token,
  "password": user_pass1
},
{headers})
 .subscribe(
  val => {


    let arr = [];  
    Object.keys(val).map(function(key){  
      arr.push({[key]:val[key]})  
      return arr;  
    });   

    this._snackBar.open(arr[1]['response']['message'], 'Close', {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
       this.form.reset();
},
response => {
  //console.log("Post call in error", response);
   this._snackBar.open('Something went wrong, Please try again!', 'Close', {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
},
() => {
  console.log("The Post observable is now completed.");
}
);

} else {

this.passwordLength = true;
}

} else {
	this.passwordnotmatch = true;
} 

}

}


  validateEmail() {
this.provideEmail = false;
 const user_email = this.form.get('email').value;
console.log("Email " + user_email);
 if (user_email === '') {
this.provideEmail = true;
} else {

var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
if(user_email.match(mailformat))
{

 const headers = new HttpHeaders()
 .set("Content-Type", "application/json");

 this.httpClient.post(this.apiURL+"email_verify.php",
 {
  "email": user_email
},
{headers})
 .subscribe(
  val => {


    let arr = [];  
    Object.keys(val).map(function(key){  
      arr.push({[key]:val[key]})  
      return arr;  
    });   

    this._snackBar.open(arr[1]['response']['message'], 'Close', {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
},
response => {
  console.log("Post call in error", response);
},
() => {
  console.log("The Post observable is now completed.");
}
);

} else {
	this.provideEmail = true;
}

}


}


}
