<div class="onboard px-5 pt-5" style="min-height: 100vh;background: linear-gradient(-135deg,#aad6cc,#55a8a3);">
  <div class="container">

    <mat-card *ngIf="forgotPass">
         <h2>Forgot Password</h2>

    
    <form [formGroup]="form" (ngSubmit)="validateEmail()">
  
            <mat-error *ngIf="provideEmail">
         Please enter valid email id! 

      </mat-error>

        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input class="custom-input" matInput placeholder="Enter your registered email" type="email" formControlName="email" required>
          </mat-form-field>
  
        
        </mat-card-content>
  
        <button mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;">Send password reset info</button>
  
      </form>

      <mat-footer>
        It may take several minutes to receive a password reset email.
        Make sure to check your junk mail.
      </mat-footer>
      
    </mat-card>

    <mat-card *ngIf="editPass">
         <h2>Set new Password</h2>

    
    <form [formGroup]="form" (ngSubmit)="resetPass()">
  
   <mat-error *ngIf="providePass">
         Please enter password

      </mat-error>
        

    <mat-error *ngIf="passwordnotmatch">
         Please enter same password

      </mat-error>
        
           <mat-error *ngIf="passwordLength">
         Password can't be longer than 20 letters

      </mat-error>

        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input class="custom-input" matInput placeholder="New password" type="password" formControlName="pass1" required>
          </mat-form-field>
  
     <mat-form-field class="example-full-width">
            <input class="custom-input" matInput placeholder="Re-type new password" type="password" formControlName="pass2" required>
          </mat-form-field>
        
        </mat-card-content>
  
        <button mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;">Update password</button>
  
      </form>
    </mat-card>
</div>
</div>