import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from '../../environments/environment';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import { CheckoutComponent } from '../checkout/checkout.component';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  constructor(public authService: AuthService,private http:HttpClient,private _snackBar: MatSnackBar,private router: Router,public dialog: MatDialog) { }

 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

apiURL = environment.apiUrl;
result: any;
current_plan_name: string;
plan_data: any[] = [];
plan_id: any;
plan_name: any;
plan_amount: any;
plan_image: any;
plan_features: any[] = [];
user_planID: any;
user_email: any;
user_mobile: any;
user_name: any;
spinner: any;
  ngOnInit(): void {
  		this.getPayments();
  		                   //console.log("Page plan_id " + this.user_planID);

  		//if(this.user_planID > 0) {
  	    //}
  }

  async Subscribe(value) {
  
     if (value) {
     
         if (value != 1) {
       const dialogRef = this.dialog.open(CheckoutComponent, {
        width: '600px',
        data: { plan_id: value,
                email: this.user_email,
                name: this.user_name,
                mobile: this.user_mobile }
      });

       dialogRef.afterClosed().subscribe(result => {
         })

       } else {
        this.upgradePlan(value);

   
     }
  }

  }

  async upgradePlan(value) {
  	     const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      this.http.post(this.apiURL+"subscribe.php",
      {
        "plan_id": value
      },
      {headers})
      .subscribe(
        val => {
          console.log("Post call successful value returned in body",  val);
        
        //  this.spinner = false;
          // console.log(typeof(val));
          
            
          let arr = [];  
          Object.keys(val).map(function(key){  
            arr.push({[key]:val[key]})  
            return arr;  
          });   
          if (arr[0]['status'] === 1) {
            //console.log("show snackbar");
            this._snackBar.open(arr[1]['response']['message'], 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          } else if (arr[0]['status'] === 0) {
            this._snackBar.open(arr[1]['response']['message'], 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['success-snackbar']

            });
            	  this.router.navigate(['/home']);
         

          } else {

          }
        },
        response => {
          console.log("Post call in error", response);
          this.spinner = false;
        },
        () => {
          console.log("The Post observable is now completed.");
        }
        );
  }

  async getPayments() {

  	const headers = new HttpHeaders()
		.set("Accept", "application/json");
		this.http.get(this.apiURL+"fetch_payments.php",
			{headers})
		.subscribe(
			val => {

				this.result = JSON.stringify(val, null, 4);
				
//			    console.log(this.result);

				  this.result = JSON.parse(JSON.stringify(val));

               if (this.result.status == 0) {

                 for(var i=0;i<this.result.response.all_plan.length;i++) {

                 	this.plan_id = this.result.response.all_plan[i]['plan_ID'];
                 	this.plan_name = this.result.response.all_plan[i]['plan_name'];
                 	this.plan_amount = this.result.response.all_plan[i]['plan_amount'];
                 	this.plan_features = this.result.response.all_plan[i]['plan_feature'];                 	
                 	this.plan_image = this.result.response.all_plan[i]['plan_image'];


                // 	this.alert_title = this.result.response.geo_alerts[i]['alert_name']
                // 	this.alert_msg = this.result.response.geo_alerts[i]['alert_message']
                // 	this.alert_time = this.result.response.geo_alerts[i]['created_at']

                 	this.plan_data.push({plan_id:this.plan_id,plan_name:this.plan_name,plan_amount:this.plan_amount,plan_feature:this.plan_features,plan_image:this.plan_image})

                   }

                  // console.log(this.plan_data);

                   for(var j=0;j<this.result.response.user_plan.length;j++) {
                   this.user_planID = this.result.response.user_plan[j]['plan_id']
                   this.current_plan_name = this.result.response.user_plan[j]['plan_name']
                   
                      }

                   this.user_mobile = this.result.response.user_data['mobile']
                   this.user_name = this.result.response.user_data['name']
                   this.user_email = this.result.response.user_data['email']

                   // if ( this.result.response.user_plan[0]['plan_id'] != null ) {
                   // this.user_planID = this.result.response.user_plan[0]['plan_id']
                   //    }
                   //console.log("Current plan_id " + this.user_planID);

                   	var   logged_user = this.authService.getUserName();
                   if(this.user_planID) {

  		          this.authService.IsAuthenticated = true;
                   	var message = "Dear " + logged_user + "! Your'e already subscribed for " + this.current_plan_name + ", to upgrade choose any other plans from below.";
                   } else {
                   	var message = "Dear " + logged_user + "! Thank you for choosing us. Please select subscription plans from below to use SPOORIT features.";
                   } 
       	    this._snackBar.open(message, 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['success-snackbar']

            });

                } else if (this.result.status == 1) {

                	// this.alert_msg = this.result.response.message;
                	// this.arr_data.push({alert_title:this.alert_msg})

                }
			},
			response => {
     this._snackBar.open("Please try again, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
			},
			() => {

			});
  }

}
