import { Component , Inject} from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//import {Observable} from "rxjs/Observable";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { AuthService } from '../services/auth.service';

import { Chart } from 'chart.js';  


export interface DialogData {
  animal: string;
}

import { ActivatedRoute, Router } from '@angular/router';
import { AdduserDialogComponent } from '../adduser-dialog/adduser-dialog.component';
import { ViewportScroller } from '@angular/common';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css']
})
export class MyDashboardComponent {
  /** Based on the screen size, switch from standard to one column per row 
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 1, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 1 },
        { title: 'Card 4', cols: 1, rows: 2 }
      ];
    })
    );*/

 // Pie
  // public pieChartOptions: ChartOptions = {
  //   responsive: true,
  //   legend: {
  //     position: 'bottom',
  //   }
  // };


  // public pieChartLabels: Label[] = [['Active members'], ['Remaining']];
  // public pieChartData: SingleDataSet = [4, 2];
  // public pieChartType: ChartType = 'pie';
  // public pieChartLegend = true;
  // public pieChartPlugins = [];
  // public pieChartColors = [
  //   {
  //     backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)'],
  //   },
  // ];

apiURL = environment.apiUrl;
meta_response: any;
members: any;
plan_id: any;
plan_name: any;
end_date: any;
today_alert = 0;
tilldate_alert = 0;
chart: any;  
total_mem: number;
img_url = "https://spoorit.evoxyz.tech/assets/steps.png"

    constructor(private breakpointObserver: BreakpointObserver,private router: Router, private viewportScroller: ViewportScroller, public dialog: MatDialog,private http:HttpClient,private _snackBar: MatSnackBar,public authService: AuthService) {
     
    }

ngOnInit() {
  this.fetchMeta(); 
}

openChart() {
   this.chart = new Chart('canvas', {  
          type: 'pie',  
          data: {  
            labels: [['Active members - ' + this.members], ['Remaining - ' + this.total_mem]],  
            datasets: [  
              {  
                data: [this.members,this.total_mem],  
                backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)'],  
                fill: true  
              }  
            ]  
          },  
          options: {  
            legend: {  
              display: true  
            }, 
          }  
        });
}

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    member_name: string;
    spinner: boolean;

   logged_user = this.authService.getUserName();
   
   openTracking() {
    this.router.navigate(['/user']);
   }


async fetchMeta() {
  const headers = new HttpHeaders().set("Accept", "application/json")
  this.http.get(this.apiURL+'usermeta.php',{headers}).subscribe(
    val => {
      //console.log(JSON.parse(JSON.stringify(val)))
      this.meta_response = JSON.parse(JSON.stringify(val))
      //console.log("members " + this.meta_response.response.members)
      this.members = this.meta_response.response.members
      this.plan_id = this.meta_response.response.plan_id
      this.plan_name = this.meta_response.response.plan_name
      this.end_date = this.meta_response.response.end_date
      this.today_alert = this.meta_response.response.today_alert
      this.tilldate_alert = this.meta_response.response.total_alert
      if(this.plan_id == 1) {
        this.total_mem = Math.abs(3 - this.members);
        } else {
          this.total_mem = Math.abs(15 - this.members);
        } 
       this.openChart();
      
      if(!this.end_date) {
        this.end_date = "_";
      }
        if(!this.plan_id) {
       this.router.navigate(['/payments']); 
  } else {
  this.authService.IsAuthenticated = true;

}
    },
    response => {},
    () => {}
  )
}





manageUser() {
   this.router.navigate(['/user']);
   this.viewportScroller.scrollToPosition([0,0])
}
manageFences() {
  this.router.navigate(['/geofence']);
}
goPayments() {
  this.router.navigate(['/payments']);
}

}
