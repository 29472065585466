<div class="onboard px-5 pt-5" style="min-height: 100vh;background: linear-gradient(-135deg,#aad6cc,#55a8a3);">
  <div class="container">

    <div class="login_card" style="">
<mat-card>
  <mat-card-content>
<div class="row">
  <div class="login_frame">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="namelogo"></div>
      <h2 style="color: #55a8a3;">Login to Dashboard</h2>

      <mat-error *ngIf="loginInvalid">

        Please provide both mobile and password to continue

      </mat-error>

      <mat-error *ngIf="allDetails">

        Please provide all details

      </mat-error>

       <mat-error *ngIf="loginOtp">
Please enter registered mobile number 

      </mat-error>

      <mat-error *ngIf="mobileDigit"> Please enter a 10 digit mobile number</mat-error>


  	<mat-form-field class="example-full-width">
					<input matInput class="custom-input" placeholder="Mobile" type="number" formControlName="mobile" required>
					  <mat-error>

          Please provide registered mobile number

        </mat-error>
	</mat-form-field>

     <mat-spinner *ngIf="spinner"></mat-spinner>

      <mat-form-field class="full-width-input">

        <input matInput class="custom-input" type="password" [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
      
      </mat-form-field>
 <!--      <div class="pass-widget" style="float: right;">
       <span class="material-icons" matSuffix (click)= "hide = !hide" style="cursor: pointer;">
          {{hide ? 'visibility' : 'visibility_off'}}
          </span>
       <div class="register-link" (click)="openDialog()" matSuffix>Use OTP</div>
        </div> -->
         <div class="clearfix"></div>
         <br/>
      <button mat-raised-button style="color: #f4f3f3;background-color: #55a8a3;width: 100%;border-radius: 25px !important;">Login</button>

    </form><br/>
    <div class="register-link">
<!--     <div class="register-link" (click)="openDialog()" matSuffix>Use OTP</div>
 -->
<!--    <p style="position: relative;right: 25%;" (click)="to_register()">Not a member? Sign Up</p>
 -->   <p style="position: relative;right: 40%;" (click)="to_forgot()">Forgot Password?</p>
   <p style="position: relative;left: 40%;" (click)="openDialog()"> Use OTP</p>
    </div>
    <div>
         <p class="register-link text-center" (click)="to_register()">Not a member? Sign Up</p>
    </div>
  </div>
<!-- <div class="clearfix"></div>
 -->
   <div class="login-detail">
    <div>
      <img src="https://spoorit.evoxyz.tech/assets/login_side.svg" class="img-responsive">
            <h1><strong>NOW AVAILABLE</strong></h1>
            <p style="font-size: 16px;">Now make your own boundaries for the people and objects you are tracking<br/>Get Alerted when the boundaries are BROKEN</p>
            <!-- <p style="font-size: 16px;">Get Alerted when the boundaries are BROKEN</p> -->
            <br/>
       <p style="font-size: 16px;">"<strong>BOUNDARIES.....</strong> Set them and then Keep them"</p>
       <p style="color: blue;text-decoration: underline;"><a href="https://spoorit.in" target="_blank">Know More....</a></p>
       <a href="https://play.google.com/store/apps/details?id=com.evoxyz.spoorit" target="_blank">
        <img src="https://www.iischoolabudhabi.com/wp-content/uploads/2019/02/iis_Get_it_on_Google_play.png" class="img-responsive" style="width: 200px;height: 60px;">
       </a>
       <p style="font-size: 11px;margin-top: 10px;">Need Help ? Email us at <a style="color: blue;" href="mailto:support@evoxyz.com">support@evoxyz.com</a></p>

    </div>
     
  </div>

  </div>
  </mat-card-content>

</mat-card>
</div>
</div>
</div>