import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component'
import { ManageUserComponent } from './manage-user/manage-user.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ShowGoogleMapComponent } from './show-google-map/show-google-map.component';
import { AuthGuard } from "./services/auth.guard";
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { AddGeofencesComponent } from './add-geofences/add-geofences.component';
import { GeofenceComponent } from './geofence/geofence.component';
import { ShowAlertComponent } from './show-alert/show-alert.component';
import { PaymentsComponent } from './payments/payments.component';
import { ReportsComponent } from './reports/reports.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: MyDashboardComponent, canActivate: [AuthGuard]},
  { path: 'user', component: ManageUserComponent, canActivate: [AuthGuard] },
  { path: 'user-location', component: ShowGoogleMapComponent, canActivate: [AuthGuard]},
  { path: 'add-geofence', component: AddGeofencesComponent, canActivate: [AuthGuard]},
  { path: 'geofence', component: GeofenceComponent, canActivate: [AuthGuard]},
  { path: 'alerts', component: ShowAlertComponent, canActivate: [AuthGuard]},
  { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard]},
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
  { path: 'register', component: SignUpComponent },
  { path: 'forgot-password/:token', component: EmailVerifyComponent },
  { path: 'forgot-password', component: EmailVerifyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
