import { Component, OnInit } from '@angular/core';

import { AuthService } from '../services/auth.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-show-alert',
  templateUrl: './show-alert.component.html',
  styleUrls: ['./show-alert.component.css']
})
export class ShowAlertComponent implements OnInit {

result: any;

alert_title: any;
alert_msg: any;
alert_time: any;
//const result : string[] = []; Without defining the array type, it by default will be never for e.g. arr: [];
 arr_data: any[] = [];

 spinner:boolean;
apiURL = environment.apiUrl;

  constructor(public authService: AuthService,private http:HttpClient) { }

  ngOnInit(): void {
  		this.authService.IsAuthenticated = true;
		this.getAlerts();
  }


  getAlerts() {
  	this.spinner = true;
  	const headers = new HttpHeaders()
		.set("Accept", "application/json");
		this.http.get(this.apiURL+"fetch_alerts.php",
			{headers})
		.subscribe(
			val => {
				this.spinner = false;

				this.result = JSON.stringify(val, null, 4);
				
			//	console.log(this.result);

				  this.result = JSON.parse(JSON.stringify(val));
               if (this.result.status == 0) {
                for(var i=0;i<this.result.response.geo_alerts.length;i++) {
                	this.alert_title = this.result.response.geo_alerts[i]['alert_name']
                	this.alert_msg = this.result.response.geo_alerts[i]['alert_message']
                	this.alert_time = this.result.response.geo_alerts[i]['created_at']

                	this.arr_data.push({alert_title:this.alert_title,alert_msg:this.alert_msg,alert_time: this.alert_time})

                   }
                                    //console.log(this.arr_data);
                } else if (this.result.status == 1) {
                	this.alert_msg = this.result.response.message;
                	this.arr_data.push({alert_title:this.alert_msg})
                }
			},
			response => {

			},
			() => {

			});
  }

}
