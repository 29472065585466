import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";

import { FormBuilder, FormGroup } from '@angular/forms';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';

@Component({
	selector: 'app-otp-dialog',
	templateUrl: './otp-dialog.component.html',
	styleUrls: ['./otp-dialog.component.css']
})
export class OtpDialogComponent implements OnInit {


	form: FormGroup;

	constructor(private fb: FormBuilder,public dialogRef: MatDialogRef<OtpDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit(): void {
		this.form = this.fb.group({
			user_otp: ['', Validators.required]
		});
	}

	otpSubmit() {
		if (this.form.valid) {
		const otp  = this.form.get('user_otp').value;
		//this.dialogRef.close("USER OTP"+ otp);
		this.dialogRef.close(otp);
	}
	}

}
