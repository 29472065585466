
<div class="main-view">
  <div class="banner">
      <div class="main-padding">

  <div class="container">

  <div class="row">
    <div class="mat-h1 for-align">
    <h1>{{logged_user}}</h1>
      <mat-chip-list style="margin-left:1%;" aria-label="Fish selection">
       <mat-chip>{{plan_name}}</mat-chip>
     </mat-chip-list>

    </div>
    <div class="col-md-12 for-align" style="color: #04032d;">

          <h1>Getting Started</h1>
          <ol class="started">
          <li>Add Member(s) to be tracked. (Members do not need to sign up)</li>
          <li>Share the generated unique tracking id to Member (Click on <i class="material-icons copy-icon">content_copy</i> icon to Copy and Share message)</li>
          <li>Ask the member to download SpoorIt App on Android phone from Google Play Store. They use their own phone number, OTP and tracking id (from shared message) to login.</li>
          </ol>

    </div>
 </div>

</div>
</div>
</div>


 <div class="overlaying" style="margin-top: -4%;">
        <div class="main-padding">
               <mat-spinner *ngIf="spinner"></mat-spinner>
  <div class="container">
<div class="row">   
      <mat-card class="col-md-3" >
        <mat-card-header>
          <mat-card-title>
          Dashboard
         </mat-card-title>
       </mat-card-header>
       <mat-card-content class="dashboard-card-content">

        <div> 
          <h3>Members Tracked: <strong>{{members}}</strong></h3>
          <h3>Current Plan: <strong>{{plan_name}}</strong></h3>

 <!--  <canvas baseChart 
    [data]="pieChartData" 
    [labels]="pieChartLabels" 
    [chartType]="pieChartType"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend"
    [colors]="pieChartColors">
  </canvas>
   -->
   <div class="chart-container" style="">    
  <canvas id="canvas"></canvas>    
</div> 
       </div>
     </mat-card-content>
   </mat-card>


      <mat-card class="col-md-3 offset-md-1" >
        <mat-card-header>
          <mat-card-title>
           Plan
         </mat-card-title>
       </mat-card-header>
       <mat-card-content class="dashboard-card-content">
        <div> 
         <h3>Your plan expires on</h3>
         <h5 style="margin-top: 15%;"> <span style="font-size: 2em;">{{end_date}}</span> </h5><br/>
          <mat-chip-list aria-label="Fish selection">
          <mat-chip  style="margin:0 auto;cursor: pointer;" class="track btn-lg" (click)=goPayments()> <span>Upgrade</span></mat-chip>
          </mat-chip-list>
          <br/>
         <h3>This is {{plan_name}} plan.</h3>

<!--             <button mat-raised-button class="btn-lg" style="background: #55a8a3;color: #f4f3f3;">Upgrade</button>
 -->
       </div>
     </mat-card-content>
   </mat-card>


      <mat-card class="col-md-3 offset-md-1" >
        <mat-card-header>
          <mat-card-title>
         Number of Geofence breaches
         </mat-card-title>
       </mat-card-header>
       <mat-card-content class="dashboard-card-content">
        <div>
         <table  style="width: 100%;">
           <thead>
             <th><h3>Breaches Today</h3></th>
             <th><h3>Till date</h3></th>
           </thead>
           <tbody>
             <tr>
               <td><h5 style="margin-top: 15%;"> <span style="font-size: 5em;color: red;">{{today_alert}}</span></h5></td>
               <td><h5 style="margin-top: 20%;"> <span style="font-size: 5em;color: red;">{{tilldate_alert}}</span> </h5></td>
             </tr>
           </tbody>
         </table>
       </div>
     </mat-card-content>
   </mat-card>

</div>
</div>
<br/><br/>

<div class="container">
  <div class="row">
       <div class="for-align">
   <mat-chip-list aria-label="Fish selection">
     <mat-chip (click)="manageUser()" class="track"> <i class="material-icons">person_add</i>  <span style="padding-left: 5px;">Manage Members</span></mat-chip>
     <mat-chip (click)="manageFences()" class="track"> <i class="material-icons">fence</i>  <span style="padding-left: 5px;">Manage Geofences</span></mat-chip>

       <!--<mat-chip (click)="openTracking()" class="track"> <i class="material-icons">verified_user</i>  <span style="padding-left: 5px;">Track User</span></mat-chip> -->   
    </mat-chip-list>
    </div>
      <mat-card class="col-md-11" style="margin-top: 3%;">
        <mat-card-header>
          <mat-card-title>
            Enrolled Members 
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div>

                     <app-display-user-dashboard></app-display-user-dashboard>

          </div>
        </mat-card-content>
      </mat-card>
</div>
</div>

</div>
</div>

</div>
