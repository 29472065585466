
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-add-geofences',
  templateUrl: './add-geofences.component.html',
  styleUrls: ['./add-geofences.component.css']
})
export class AddGeofencesComponent implements OnInit {

apiURL = environment.apiUrl;

title: string = 'AGM project';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  nameEmpty:boolean;
  checked: false;
  spinner: boolean;
  message: any;
  arr = [];


    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('search')
  public searchElementRef: ElementRef;

  	constructor(public authService: AuthService, private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,private _snackBar: MatSnackBar, private http:HttpClient) { }


	ngOnInit(): void {
		this.authService.IsAuthenticated = true;
		console.log("Navigation " + this.authService.IsAuthenticated);

        this.get_geofence();
           
	        this.latitude = 28.4595;
        	this.longitude =  77.0266;
        	 this.zoom = 19;
		 //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
       this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
        	console.log("In places" + JSON.stringify(this.geoCoder));
    
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          
          //set latitude, longitude and zoom

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 19;
             
           //console.log("marker " + this.latitude + this.longitude);
        });
      });
    });

	}


    url  = 'https://spoorit.evoxyz.tech/assets/marker.svg';
	radius = 20;

// Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 19;
     
      });
    } else {
    	console.log("Seems Location Disabled");
    }
  }



showEvent(e) {
	this.latitude = e.lat;
    this.longitude =  e.lng;
}


     radiusEvent(type,$event) {
    //console.log(type,$event);
    this.radius = Math.round($event);
  }

  submit(value1) {
this.spinner = true;
console.log("Submit " + this.latitude + this.longitude + this.radius + " " + value1 + " " + this.checked);

  	this.nameEmpty = false;
  	if (!value1) {
  	this.nameEmpty = true;
  	this.spinner = false;
  } else {

const headers = new HttpHeaders()
 .set("Content-Type", "application/json");

 this.http.post(this.apiURL+"add_geofence.php",
 {
   'fence_area_name' : value1,
   'fence_area_lat' : this.latitude,
   'fence_area_lng' : this.longitude,
   'fence_area_radius' : this.radius,
   'fence_enable_user' : this.checked
},
{headers})
 .subscribe(
  val => {
    console.log("Verify otp call successful value returned in body",  JSON.stringify(val, null, 4));
    
    var result = JSON.parse(JSON.stringify(val))

    if (result.status === 0) {

    	this.message = 'Geofence successfully added!';


    } else {
      
    	this.message = result.response.message;    
      console.log(this.message)	 
    }
    this._snackBar.open(this.message, 'Close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });

},
response => {

	 this._snackBar.open('Something went wrong. Please try again!', 'Close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
  console.log("Post call in error", response);
},
() => {
  console.log("The Post observable is now completed.");
}
);
this.spinner = false;
  	  
}
  }


get_geofence() {

	const headers = new HttpHeaders().set("Accept","application/json");

	this.http.get(this.apiURL+"fetch_geofence.php", {headers})
	.subscribe(
		val=> {
       //console.log("FETCH " , JSON.stringify(val,null,4 ));
          var results = JSON.parse(JSON.stringify(val));

          if (results.status === 0) {
          var res_size = Object.keys(results.response.geofence).length;
           
           //this.arr.push({Sno: '', Name: ''});
          for(var i=0;i<res_size;i++){
          //console.log(results.response.geofence[0]['id'] + " " + res_size);
          this.arr.push({Sno: i+1, Name: results.response.geofence[i]['name']});
             }
            // console.log("Array ", this.arr);
          } else {

          }

		},
		response=> {
         console.log("In error");
		},
		()=> {

		}
		);
}


}
