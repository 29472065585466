<section class="main-view">
  <div class="banner">
          <div class="main-padding">
    <div class="container">
      <div class="row">
       <h1 class="mat-h1 for-align">Track Members</h1>

     </div>
   </div>
 </div>
</div>

 <div class="overlaying">
        <div class="main-padding">
  <div class="container">
   <div class="row">

    <mat-card style="background: #fff;" class="col-md-12">

      <mat-card-header>
        <mat-card-title>
          Manage Members 
          <span style="float: right;margin-bottom: 1%;">
           <mat-chip-list class="for-align" aria-label="Fish selection">
             <mat-chip (click)="openUser()" class="track"> <i class="material-icons">person_add</i> Add Member</mat-chip>
           </mat-chip-list>
         </span>
       </mat-card-title>   <br/>      
     </mat-card-header>

     <mat-card-content>
      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
            <th>S.No</th>
            <th>Tracking Id</th>
            <th>Name</th>
            <th>Mobile number</th>
            <th>Copy message</th>
            <th>Geofences</th>
            <th>Status</th>            
            <th>Last Seen</th>
         <!--    <th>Action</th> -->
            <th>Action</th>
            <th>Created on</th>
          </thead>
          <tbody>


            <tr *ngFor = "let user of arr; index as i">
              <td>{{i+1}}</td>
              <td><a style="cursor: pointer;">{{user.tracking_id}}</a></td>
              <td>{{user.name}}</td>
              <td>{{user.mobile}}</td>
               <td>
               <i class="material-icons copy-icon" (click)="copyLink(user.share)"  matTooltip="click to copy message to be sent to member">content_copy</i>
             </td>
              <td>{{user.is_fence}}</td>
              <td [ngClass]="{
      'text-red':user.live_status === 'Offline',
      'text-green':user.live_status === 'Online'}">{{user.live_status}}</td>              
              <td>{{user.last_seen}}</td>
             
          <!--    <td> <label>Disable  </label> 
              <mat-slide-toggle ariaLabel="Close" color="primary" >Enable</mat-slide-toggle>
            </td> -->
            <td>
              <i (click)="editUser(user.all_fence,user.name,user.kid_id,user.mobile,user.kid_fence)" class="material-icons" style="cursor: pointer;color:#55a8a3;padding-right: 5px;" >edit</i>
<!--               <i class="material-icons" style="cursor: pointer;color:#55a8a3;" (click)="upGrade()">delete</i>
 -->            </td>
            <td>{{user.created_at}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-card-content>

</mat-card>


</div>
</div>
                    <mat-spinner *ngIf="spinner"></mat-spinner>

<div class="container">
 <div class="row" style="margin-top: 4%;">

   <mat-card class="col-md-12">
    <mat-card-header>
      <mat-card-title>
        Member Tracking 
        <span style="float: right;margin-bottom: 1%;">
      <!--    <mat-chip-list aria-label="Fish selection">
          <mat-chip  style="" class="track btn-lg" > <span>Refresh</span></mat-chip>
                  </mat-chip-list> -->
                  <button mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;" class="btn-lg" (click)="setCurrentLocation(true)">Refresh</button>
                </span>
              </mat-card-title>   <br/>      
            </mat-card-header>

            <mat-card-content >


              <div id="map" >
                <mat-spinner *ngIf="map_spinner"></mat-spinner>

                <agm-map

                [latitude]="lat"
                [longitude]="lng"
                [zoom]="17"
                >
                <agm-marker
                *ngFor="let marker of marker_result"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [opacity]="marker.alpha"
                [markerDraggable]="false"
                [iconUrl]="url"
                [title]="marker.user"
                [label]="SHI"
                >
            <!--     <agm-marker
                *ngFor="let marker of marker_data"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [iconUrl]="url"
                > -->
                <agm-info-window #infowindow>
                  <div>
                    <p> <strong> {{marker.user}} </strong></p>
                    <p><strong>Location </strong><br/> {{marker.location}}</p>
                    <p><strong>Last updated time</strong><br/> {{marker.time}}</p>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </div>

        </mat-card-content>
      </mat-card>



    </div>
  </div>

</div>
</div>
</section>

