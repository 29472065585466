import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";


export interface PeriodicElement {
	name: string;
	position: number;
	status: string;
}


const ELEMENT_DATA: PeriodicElement[] = [
{position: 1, name: 'Alpha', status: '✔'},
];


import { interval, Subscription } from 'rxjs';

const source = interval(5000);

@Component({
	selector: 'app-show-google-map',
	templateUrl: './show-google-map.component.html',
	styleUrls: ['./show-google-map.component.css']
})
export class ShowGoogleMapComponent implements OnInit {



	title: string = 'AGM project';
	latitude: number;
	longitude: number;
	zoom:number;
	result:any;
	marker_result = [] as any;
	details: any;
  position = [28.4183269, 77.1010208];
     
    new_position = [28.417498,77.099513];
       i = 0;
     deltaLat: number;
     deltaLng: number;

    url  = 'http://spoorit.evoxyz.tech/assets/marker.svg';

  subscription: Subscription;

	constructor(public authService: AuthService,private http:HttpClient) {

	this.subscription = source.subscribe(
  val => {
//this.transition(this.new_position);
  }
  );
   }


	ngOnInit(): void {
		this.authService.IsAuthenticated = true;
console.log("Navigation " + this.authService.IsAuthenticated);
		//this.setCurrentLocation();
		      this.marker_result.push({lat:28.4183269,lng:77.1010208})


	}

	displayedColumns: string[] = ['position', 'name', 'status'];
	dataSource = ELEMENT_DATA;

	lat = 28.4183269;
	lng = 77.1010208;

	selectedMarker = null;

//	url  = 'http://spoorit.evoxyz.tech/web_backend/staffmarkerpurple.png';
	markers = [
	// These are all just random coordinates from https://www.random.org/geographic-coordinates/
	{ lat: 28.462734, lng: 77.005017,  user: 'Alpha',  location: 'Gurgaon', time: '12pm' },
	{ lat: 28.463036, lng:  77.042783,  user: 'Beta',  location: 'Gurgaon', time: '1pm' },
	{ lat: 28.429528, lng: 77.024072,  user: 'Gamma',  location: 'Gurgaon', time: '2pm' }
	];

	addMarker(lat: number, lng: number, user: string, location: string, time: string) {
		this.markers.push({ lat, lng, user, location, time });
	}

	max(coordType: 'lat' | 'lng'): number {
		return Math.max(...this.markers.map(marker => marker[coordType]));
	}

	min(coordType: 'lat' | 'lng'): number {
		return Math.min(...this.markers.map(marker => marker[coordType]));
	}

	selectMarker(event) {
		this.selectedMarker = {
			lat: event.latitude,
			lng: event.longitude
		};
	}

transition(new_position) {
       // var i = 0;
        this.deltaLat = (this.new_position[0] - this.position[0])/5;
        this.deltaLng = (this.new_position[1] - this.position[1])/5;
      //console.log("delta1 " + this.deltaLat + " " + this.deltaLng);

        this.moveMarker(this.position);
    }
    
     moveMarker(position){
     
        //this.deltaLat = -0.00002380899999998575;
        //this.deltaLng = -0.00008025732000007224;

          //    console.log("delta " + this.deltaLat + " " + this.deltaLng);

      //console.log("position before" + this.position);
        this.position[0] += this.deltaLat;
        this.position[1] += this.deltaLng;
        //this.position.push({th})
      //console.log("position later" + this.position);
             this.marker_result.length = 0
        this.marker_result.push({lat: this.position[0],lng:this.position[1]})
        if(this.i!=5){
            this.i++;
            setTimeout(() => this.moveMarker(this.position), 1000);

            //setTimeout(this.moveMarker(this.position), 0);
        }
    }

	// Get Current Location Coordinates
	private setCurrentLocation() {

		const headers = new HttpHeaders()
		.set("Accept", "application/json");
		this.http.get("http://spoorit.evoxyz.tech/web_backend/fetch_location.php",
			{headers})
		.subscribe(
			val => {

				//console.log("Get call successful value returned in body", val);

				//let response = res.body;
				//this.countries = response.data.children;
				//console.log("All location" + val);
				this.marker_result = val;
				this.result = JSON.stringify(val, null, 4);
				//console.log("Response " + this.result);
				/*JSON.parse(this.result, (key, value) => {
  if (typeof value === 'string') {
    return value.toUpperCase();
  }
  return value;
  if (key === 'lat') {
  	//console.log(" value " + value);
  	
  }
  //console.log("Key " + key + " value " + value);
});*/

				var datas = JSON.parse(this.result);


				var size = Object.keys(val).length;
				//console.log("Length " + size);
                  let arr = [];
				for(var i=0;i<size;i++) {

				//console.log(" time " + datas[i]['time']);
				var minutes = this.timediff(datas[i]['time']);
				//console.log("Minutes " + minutes);
				 if (minutes <= 5) {
				 	var status_sign = '✔️';
				 } else {
				 	var status_sign = '❌'
				 }
					arr.push({position: i+1, name: datas[i]['user'], status: status_sign});
				//console.log("lat " + datas[i]['lat'] + " lng " + datas[i]['lng'] + " name " + datas[i]['user']);
			    }

			    this.details = arr;

                this.lat = datas[0]['lat'];
                this.lng = datas[0]['lng'];

              

			},
			response => {
				console.log("Get call in error", response);
			},
			() => {
				console.log("The Get observable is now completed.");
			}
			);



		//if ('geolocation' in navigator) {
			//	navigator.geolocation.getCurrentPosition((position) => {

				//this.latitude = position.coords.latitude;
				//this.longitude = position.coords.longitude;

				/*this.latitude = 28.4595;
				this.longitude = 77.0266;
				this.zoom = 15;*/
				//	});
				//}
			}

			timediff(trackingtime) {
				  var current =new Date().toLocaleString();
                
                console.log("1 " + current + " " + trackingtime)
                var dt1 = new Date(trackingtime);
                var dt2 = new Date(current);
              console.log('2 ' + current + " " + dt1 + " " + dt2);
                  var diff =(dt2.getTime() - dt1.getTime()) / 1000;

                  diff /= (60);
                  //console.log(current + " " + diff);
                 var minutes =  Math.abs(Math.round(diff));
                 return minutes;
			}

		}
