<div class="onboard px-5 pt-5" style="min-height: 100vh;background: linear-gradient(-135deg,#aad6cc,#55a8a3);">
  <div class="container">

    <div class="login_card" style="">
	<mat-card>
					<mat-card-content>

<div class="row">
  <div class="login_frame">

      <div class="namelogo"></div>
						<h2 style="color: #55a8a3;">Welcome ! Sign-up to create your account</h2>

			<mat-error *ngIf="signInvalid">

				Something went wrong, please fill / re-check all mandatory fields and re-try!

			</mat-error>

			      <mat-error *ngIf="mobileDigit"> Please enter a 10 digit mobile number</mat-error>

                  <mat-error *ngIf="emailValid"> Please provide correct email format </mat-error>


				<form class="example-form" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="signup_click">

<!-- <table style="width:100%;" cellspacing="0"><tr>
    <td> -->
    	<div class="col-md-12">
    		<div class="row">
    	<div class="col-md-6">
    	<mat-form-field class="example-full-width">
      <mat-label>First name</mat-label>
      	<input matInput class="custom-input" placeholder="First name" type="text" formControlName="username" required>
    </mat-form-field>
</div>
   <!--  </td>
    <td> -->
    		<div class="col-md-6">
    
    	<mat-form-field class="example-full-width">
      <mat-label>Last Name</mat-label>
		 <input matInput class="custom-input" placeholder="Last name" type="text" formControlName="lastname">
    </mat-form-field>
</div>
</div>
</div>
<!-- 
</td>
  </tr>
</table> -->

				<mat-form-field class="example-full-width">

					<input type="email" class="custom-input" matInput placeholder="Email" formControlName="useremail" required>

				</mat-form-field><!-- 
                  <mat-spinner *ngIf="spinner"></mat-spinner> -->
				<mat-form-field class="example-full-width">
					<input matInput class="custom-input"  placeholder="Mobile" type="number" formControlName="mobile" required>
				</mat-form-field>

	        <mat-form-field class="example-full-width">
					<input matInput class="custom-input"  type="password" placeholder="Password" formControlName="password" required>
				</mat-form-field>
			

			<button mat-raised-button color="primary" style="color: #f4f3f3;background-color: #55a8a3;">Proceed</button>

		</form>

		<form class="example-form" [formGroup]="form" (ngSubmit)="finalSubmit()" *ngIf="proceed_click">


                  						<h3 style="color: #55a8a3;">Looking for</h3>

	  <mat-radio-group  aria-label="Looking for" formControlName="user_type" (change)=selectType($event)>
          <mat-radio-button style="float: left;" value="individual" >  Individual Tracking</mat-radio-button>
          <mat-radio-button  value="enterprise"> Enterprise Tracking </mat-radio-button>
      </mat-radio-group>


				<mat-form-field class="example-full-width" *ngIf="ifOrganisation">

					<input type="text" class="custom-input" matInput placeholder="Your Organisation name" formControlName="userorganisation" required>

				</mat-form-field>

				<mat-form-field class="example-full-width">

					<input type="number" class="custom-input" matInput placeholder="Tentative number of members" formControlName="useremployee" required>

				</mat-form-field>

                  <mat-spinner *ngIf="spinner"></mat-spinner>
                  
                  						<h3 style="color: #55a8a3;">Choose Subscription Plan</h3>

		<mat-form-field appearance="fill">
<!--   <mat-label>Choose Subscription Plan</mat-label>
 -->  <mat-select matInput formControlName="user_subs" required><!-- 
    <mat-option *ngFor="let food of foods" [value]="food.value">
      {{food.viewValue}}
    </mat-option> -->
    <mat-option value="SPOOR-M">SPOOR-M (Basic Free)</mat-option>
    <mat-option value="SPOOR-L">SPOOR-L (Paid 499/mo)</mat-option>
  </mat-select>
</mat-form-field>
  <p>Plans selected here can be changed anytime from dashbaord or you may reach us for the same.</p>

			<button mat-raised-button color="primary" style="color: #f4f3f3;background-color: #55a8a3;">Sign Up</button>

		</form>
		<br/>
		<p class="register-link" (click)="to_login()">Already member? Login</p>
	</div>

	 <div class="login-detail">
    <div>
      <img src="https://spoorit.evoxyz.tech/assets/signup_side.png" class="img-responsive">

             <h1 style="margin-top: 10px;"><strong>Why choose Spoorit ?</strong></h1>
            <p style="font-size: 16px;">SpoorIt gives you the power to manage your people and objects on a <strong>SINGLE SCREEN</strong></p>
            <p style="font-size: 16px">Welcome to the world of Tracking and Tracing !!</p><!-- 
       <p style="font-size: 16px;">"<strong>BOUNDARIES.....</strong> Set them and then Keep them"</p> -->
     <p style="color: blue;text-decoration: underline;"><a href="https://spoorit.in" target="_blank">Know More....</a></p>
       <a href="https://play.google.com/store/apps/details?id=com.evoxyz.spoorit" target="_blank">
        <img src="https://www.iischoolabudhabi.com/wp-content/uploads/2019/02/iis_Get_it_on_Google_play.png" class="img-responsive" style="width: 200px;height: 60px;">
       </a>
        <br/>
       <p style="font-size: 11px;margin-top: 10px;">Need Help ? Email us at <a style="color: blue;" href="mailto:support@evoxyz.com">support@evoxyz.com</a></p>
    </div>
     
  </div>

	</div>
		</mat-card-content>
	</mat-card>
</div>
</div>
</div>
