<div class="table-responsive">
<table mat-table  [dataSource]="result"  class="table">


	<!-- Position Column -->
	<ng-container matColumnDef="position">
		<th mat-header-cell *matHeaderCellDef> S.No. </th>
		<td mat-cell *matCellDef="let element"> {{element.position}} </td>
	</ng-container>

	<!-- Tracking Id Column -->
	<ng-container matColumnDef="trackingid">
		<th mat-header-cell *matHeaderCellDef> Tracking Id</th>
		<td mat-cell *matCellDef="let element"> {{element.trackingid}} </td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="name">
		<th mat-header-cell *matHeaderCellDef> Name </th>
		<td mat-cell *matCellDef="let element"> {{element.name}} 

		</td>
	</ng-container>


	<!-- Name Column -->
	<ng-container matColumnDef="mobile">
		<th mat-header-cell *matHeaderCellDef> Mobile </th>
		<td mat-cell *matCellDef="let element" >  {{element.mobile}} 

		</td>
	</ng-container>

	<!-- Share Column -->
	<ng-container matColumnDef="share">
		<th mat-header-cell *matHeaderCellDef> Copy message for member</th>
		<td mat-cell *matCellDef="let element"> 
			<i class="material-icons copy-icon" (click)="copyLink(element.share)"  matTooltip="click to copy message to be sent to member">content_copy</i> 
			<!--<button mat-icon-button shareButton="whatsapp"
			#waBtn="shareButton"
			description="hello">
			<fa-icon *ngIf="waBtn" [icon]="waBtn.icon" size="lg"></fa-icon>
		</button>-->
	</td>
</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="status">
		<th mat-header-cell *matHeaderCellDef> Status </th>
		<td mat-cell *matCellDef="let element" [ngClass]="{
      'text-red':element.status === 'Offline',
      'text-green':element.status === 'Online'}" > {{element.status}} 

		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="last_seen">
		<th mat-header-cell *matHeaderCellDef> Last Seen </th>
		<td mat-cell *matCellDef="let element" > {{element.last_seen}} 

		</td>
	</ng-container>



<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>