<h1 mat-dialog-title>Enter OTP sent to {{data.otp_mobile}}</h1>
<p style="font-size: 10px;">We will send OTP from EVOXYZ name!</p>
<form [formGroup]="form" (ngSubmit)="otpSubmit()">
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>OTP</mat-label>
    <input class="form-control" type="number" formControlName="user_otp"  required>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button cdkFocusInitial>Verify</button>
</div>
</form>