<section class="main-view gmap">
	<div class="banner">
		      <div class="main-padding">
	<div class="container">
<div class="row">

 <h1 class="mat-h1 for-align">Alerts</h1>

</div>
</div>
</div>
</div>
<div class="overlaying">
	      <div class="main-padding">
	<div class="container">
		<div class="row">
	           <mat-card class="col-md-10">
				
					<mat-card-content >
						
                      <mat-spinner *ngIf="spinner"></mat-spinner>
                       <mat-card *ngFor="let data of arr_data" style="margin-bottom: 5%;">
                       	<p><span style="color: red;">{{data.alert_title}}  {{data.alert_time}}</span> <span style="padding-left: 4%;">{{data.alert_msg}}.</span></p>
                       	</mat-card>
                       


					</mat-card-content>
			   </mat-card>

   <!--        <div class="col-md-5">
			   <mat-card >
				
					<mat-card-content >
						<p>Analytics</p>
					</mat-card-content>
			   </mat-card>
			</div> -->

</div>
</div>
</div>
</div>
</section>
