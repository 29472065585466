import { Component, OnInit , Inject} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {  MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { OtpDialogComponent } from '../otp-dialog/otp-dialog.component';
import { AuthService } from '../services/auth.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {


  form: FormGroup;
  public signInvalid: boolean;
  private formSubmitAttempt: boolean;
  public spinner: boolean;
  constructor(private router: Router, private fb: FormBuilder,private http:HttpClient,private _snackBar: MatSnackBar,public dialog: MatDialog, public authService: AuthService) { }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  userotp: number;
  otp_mobile: number;
  apiURL = environment.apiUrl;
  proceed_click: boolean;
  signup_click: boolean;
  verify_mobile: number;
  mobileDigit: boolean;
  emailValid: boolean;
  ifOrganisation: boolean;


  ngOnInit(): void {

  this.proceed_click = false;
  this.authService.IsAuthenticated = false;
  this.signup_click = true;
  this.ifOrganisation = false;

   this.form = this.fb.group({
    username: ['', Validators.required],
    useremail: ['', Validators.email],
    password: ['', Validators.required],
    mobile: ['', Validators.required],
    userorganisation: ['',Validators.required],
    useremployee: ['',Validators.required],
    user_subs: ['', Validators.required],
    user_type: ['', Validators.required],
    lastname:['']

    
  });
 }

 async onSubmit () {


  this.signInvalid = false;

  this.formSubmitAttempt = false;
  this.mobileDigit = false;
  this.emailValid = false;
  const username = (this.form.get('username').value) + ' ' + (this.form.get('lastname').value);
  const password = (this.form.get('password').value);
  const email = (this.form.get('useremail').value);
  const mobile = (this.form.get('mobile').value);

  this.spinner = true;

//  console.log("data " + username + password + email + mobile);

 var bool = this.validateEmail(email)
 if (bool) {
  if ((email === '') || (username === '') || (password === '') || (mobile === '')) {
   this.signInvalid = true;
   this.spinner = false;
 } else {
  if (mobile.toString().length == 10) {
   this.httpPostExample(username,mobile,email,password);
  } else {
    this.mobileDigit = true;
    this.spinner = false;
  }
 }
} else {
  this.emailValid = true;
}
}

 validateEmail(email) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) {
    return true
  } else {
    return false
  }
  return false
  //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

httpPostExample(username,mobile,email,password) {

  const headers = new HttpHeaders()
  .set("Content-Type", "application/json");

  this.http.post(this.apiURL+"signup.php",
  {
    "name": username.toLowerCase(),
    "mobile": mobile,
    "email": email.replace(/\s/g, "").toLowerCase(),
    "password": password.replace(/\s/g, "").toLowerCase(),
    "type": 0

  },
  {headers})
  .subscribe(
    val => {
   //   console.log("Post call successful value returned in body",  val);
      this.spinner = false;
     // console.log(typeof(val));


      let arr = [];  
      Object.keys(val).map(function(key){  
        arr.push({[key]:val[key]})  
        return arr;  
      });   
      //console.log('Array=',arr[0]['status']);
      //console.log('Array=',arr[1]['response']['message']);
      if (arr[0]['status'] === 0) {
        //console.log("show snackbar");
          this.signup_click = false;
          this.proceed_click = true;
          this.verify_mobile = arr[1]['response']['verify_mobile'];
      } else if (arr[0]['status'] === 1) {
       this._snackBar.open(arr[1]['response']['message'], 'Close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
     }
     //window.location.reload();
   },
   response => {
    this._snackBar.open("Please try again, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 2000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
    //console.log("Post call in error", response);
    this.spinner = false;
  },
  () => {
    console.log("The Post observable is now completed.");
  }
  );

}

async finalSubmit() {

  //console.log("Mobile " + this.verify_mobile);

  this.spinner = true;


  var userorganisation = (this.form.get('userorganisation').value);
  const useremployee = (this.form.get('useremployee').value);
  const user_subs = (this.form.get('user_subs').value);
  const user_type = (this.form.get('user_type').value);

  this.signInvalid = false;

  //console.log("data " + userorganisation + useremployee + user_subs + this.verify_mobile);

  if ((useremployee === '') || (user_subs === '') || (user_type === '') || (user_type === 'enterprise' &&  userorganisation == ''))  {

   this.signInvalid = true;
   this.spinner = false;
 } else {

   if (user_type === 'individual') {
    userorganisation = 'N/A'
   }

   // console.log("Send request " + userorganisation)   
  const headers = new HttpHeaders()
  .set("Content-Type", "application/json");

  this.http.post(this.apiURL+"signup.php",
  {
    "user_type": user_type.replace(/\s/g, ""),
    "organisation": userorganisation.replace(/\s/g, ""),
    "employee": useremployee,
    "user_subs": user_subs.replace(/\s/g, ""),
    "mobile": this.verify_mobile,
    "type": 1
  },
  {headers})
  .subscribe(
    val => {
      this.spinner = false;

      let arr = [];  
      Object.keys(val).map(function(key){  
        arr.push({[key]:val[key]})  
        return arr;  
      });   
      if (arr[0]['status'] === 0) {

        const dialogRef = this.dialog.open(OtpDialogComponent, {
          width: '400px',
          data: {  otp_mobile: this.verify_mobile}
        });

        dialogRef.afterClosed().subscribe(result => {
         // console.log('The dialog was closed' + result);
          this.userotp = result;
          //console.log(this.userotp);
          this.validateOTP(this.userotp,this.verify_mobile);


          //window.location.reload();
        });  
      } else if (arr[0]['status'] === 1) {
       this._snackBar.open(arr[1]['response']['message'], 'Close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
     }
     //window.location.reload();
   },
   response => {
   // console.log("Post call in error", response);
    this.spinner = false;
  },
  () => {
    //console.log("The Post observable is now completed.");
  }
  );

}

}

validateOTP(otp,mobile) {
   const headers = new HttpHeaders()
      .set("Content-Type", "application/json");

      this.http.post(this.apiURL+"validate_otp.php",
      {
        "otp": otp,
        "mobile": mobile
      },
      {headers})
      .subscribe(
        val => {
         // console.log("Verify otp call successful value returned in body",  val);
        
          let arr = [];  
          Object.keys(val).map(function(key){  
            arr.push({[key]:val[key]})  
            return arr;  
          });   
       
           let snackBarRef =  this._snackBar.open(arr[1]['response']['message'], 'Close', {
              duration: 2000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });

          if (arr[0]['status'] === 0) {

            snackBarRef.afterDismissed().subscribe(() => {
              this.router.navigate(['/login']);
              
            });
          }
         
     
        },
        response => {
             this._snackBar.open("Please try again, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 2000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
         // console.log("Post call in error", response);
          this.spinner = false;
        },
        () => {
         // console.log("The Post observable is now completed.");
        }
        );
}

to_login() {
	this.router.navigate(['/login']);
}

selectType(event) {
  //console.log("Event " + event.value)
  if(event.value) {
    if (event.value == 'enterprise') {
      this.ifOrganisation = true;   
    } else {
      this.ifOrganisation = false;   
    }
  }
}

}
