<section class="main-view gmap">
  <div class="banner">
          <div class="main-padding">

	<div class="container">
<div class="row">
   <h1 class="mat-h1">Create Geofences</h1>
</div>
</div>
</div>
</div>
<div class="overlaying">
        <div class="main-padding">
  <div class="container">
    <div class="row">
				<mat-card class="col-md-8">
				
					<mat-card-content >
  <div id="map" >


<div class="form-group">
    <label style="color: #fff;">Provide address</label>
    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Geofence Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
  </div><br/><br/>

  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"  [mapDraggable]="false">
     <agm-marker [latitude]="latitude" [longitude]="longitude" [iconUrl]="url" 
      ></agm-marker>

        <agm-circle [latitude]="latitude" [longitude]="longitude" [radius]="radius" [fillColor]="'green'"
         [circleDraggable]="true" [editable]="true" (radiusChange)="radiusEvent('radiusChange',$event)" (centerChange)="showEvent($event)">
    </agm-circle> 
  </agm-map>
</div>

</mat-card-content>
</mat-card>

<div class="col-md-4">
	<div class="row">
		<div class="col-md-12">
			<mat-card style="background: #fff;margin-top: 9%;">
				
				<mat-card-header>
					<mat-card-title>
						New Geofence details <br/><br/>

						<mat-error *ngIf="nameEmpty">
							Please provide fence name!
						</mat-error>
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="dashboard-card-content">
					<div style="text-align: justify;"> 
						<form #myForm="ngForm">
					<p><strong>Latitude:</strong> <span class="fence">{{latitude}}</span></p>
					<p><strong>Longitude:</strong> <span class="fence">{{longitude}}</span></p>
					<p><strong>Radius:</strong> <span class="fence">{{radius}}m</span></p>
					<mat-spinner *ngIf=spinner></mat-spinner>
					<p> <strong>Fence name</strong></p>
					<input class="form-control" #fence_name name="fence_name" maxlength="20" type="text"> <br/>
                    <mat-checkbox class="example-margin"  [(ngModel)]="checked" name="checked"><strong>Apply to all users</strong></mat-checkbox> <br/><br/>
					<button mat-raised-button style="background: #55a8a3;color: #f4f3f3;border-radius: 16px;" (click)="submit(fence_name.value); myForm.resetForm()">Save</button>
				     </form>
				</div>
				</mat-card-content>

			</mat-card>
		</div>


	</div>
</div>

</div>
</div>
</div>
</div>
</section>
